import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { slsConfigReader } from 'src/app/helpers/convert.helper';
import Amplify from 'aws-amplify';
import slsConfig from './serverless-stack-output.json';

Amplify.configure(slsConfigReader(slsConfig));

if (environment.production) {
  enableProdMode();
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
});
