import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Dialog } from 'src/app/models/dialog.model';
import { MatDialog } from '@angular/material/dialog';

@Injectable({ providedIn: 'root' })
export class MessageService {
    constructor(private dialog?: MatDialog){}

    messages: string[] = [];
    subscription = new Subject<Dialog>();

    set(data: Dialog): void {
        this.subscription.next(data);
    }

    add(message: string): void {
        this.messages.push(message);
    }

    clear(): void {
        this.messages = [];
    }

    close(): void {
        this.dialog.closeAll();
    }
}

// tslint:disable-next-line:interface-name
export interface Message {
    type: string;
    message: string;
}
