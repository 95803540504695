<div id="uploader-wrapper">
  <label
    class="upload"
    [ngClass]="{ 'file-over': fileOver }"
    ng2FileDrop (fileOver)="fileOverBase($event)"
    (change)="onChange($event)"
    *ngIf="uploadStatus !== 'begin' && uploadStatus !== 'complete'"
    [uploader]="uploader">
    <div *ngIf="!uploader?.queue.length">
      <mat-icon class="ic" mat-list-icon>attach_file</mat-icon>
      <div class="upload-section-header">Drag and drop your file here</div>
      <div class="upload-section-header smaller">or</div>
      <div class="faux-button">Browse</div>
    </div>

    <div id="upload-ready_wrapper" *ngIf="uploader?.queue.length">

      <h5>Ready to upload:</h5>
      <h5 class="filename">{{ uploader?.queue[0]?.file.name }}</h5>

        <div id="choose-format_wrapper">
            <h6>Select your desired output format(s) for download:</h6>
            <div id="upload-format-message">Please be advised that choosing multiple format options will require more credits, as each page is processed separately per format type. </div>

            <mat-checkbox class="choose-json-format" (change)="selectUploadFormat($event, 'json')">
                <fa-icon [icon]="faFileAlt" size="2x"></fa-icon>
                <mat-label class="text">JSON</mat-label>
            </mat-checkbox>

            <mat-checkbox class="choose-pdf-format" (change)="selectUploadFormat($event, 'pdf')">
              <fa-icon [icon]="faFilePdf" size="2x"></fa-icon>
                <mat-label class="text">PDF</mat-label>
            </mat-checkbox>
        </div>

		<div *ngIf="uploadCost" id="upload-cost" class="upload-cost_header">
			Cost: &nbsp; <strong>{{ uploadCost }} point<span *ngIf="uploadCost > 1">s</span></strong>
		</div>

		<div *ngIf="cantUpload === true && numFormats > 0" id="cant-upload">
			<div class="upload-cost_header">Balance: &nbsp; <strong>{{pointsLeft}} point<span *ngIf="uploadCost > 1">s</span></strong></div>
			You don't have enough points to complete this upload.
		</div>

        <div class="upload-btns">
          <button class="confirm" mat-flat-button (click)="doUpload($event)" [ngClass]="{'hidden': readyToUpload === false}">
            <span mat-line>Upload </span>
          </button>

          <button class="cancel" mat-flat-button (click)="resetForm($event)">
            <span mat-line>Cancel</span>
          </button>
        </div>
    </div>

    <input style="display: none" type="file" accept=".pdf" ng2FileSelect [uploader]="uploader"/>
  </label>
</div>

<div
  id="upload-progress_wrapper" *ngIf="uploadStatus && (uploadStatus != 'init' && uploadStatus != 'finish')">
  <div
    class="upload-progress-bar"
    *ngFor="let upload of uploader.queue; let i = index"
    [ngClass]="{ complete: uploadStatus === 'complete', long: upload.file.name.length > 90 }">
    <div id="upload-headers">
      <span class="file-pdf"><fa-icon [icon]="faFilePdf" size="2x"></fa-icon></span>
      <div id="upload-headers_container">
        <div class="filename-header small-header">Filename</div>
        <div class="upload-filename">
          {{ upload.file.name }}
        </div>
      </div>
      <div class="filesize small-header"> {{ upload.file.size | filesize }}</div>
    </div>

    <div id="progress-bar">
      <div class="filesize small-header"> {{ upload.file.size | filesize }}</div>
      <mat-progress-bar
        mode="indeterminate"
        *ngIf="uploadStatus === 'begin'"></mat-progress-bar>
      <mat-progress-bar
        mode="determinate"
        value="100" 
        *ngIf="uploadStatus === 'complete'"></mat-progress-bar>
        <span class="stop-upload-btn" (click)="cancelUpload(upload, i)">
            <img src="./assets/icon-close.png" width="24" height="24" alt="close icon" />
        </span>
        <span class="complete-icon">
            <img src="./assets/icon-complete.png" width="24" height="24" alt="complete icon" />
        </span>
    </div>
  </div>
</div>
