import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { RouterService } from 'src/app/services/router.service';

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss'],
})
export class SubscriptionsComponent implements OnInit {
  public displayName = '';
  public subscriptions = [];
  public isLoading = true;
  constructor(public authService: AuthService, private routerService: RouterService) {
    authService.getSubscriptions()
    .then(res => {
      if (res.data) {
        this.isLoading = false;
        this.subscriptions = res.data.subscriptions;
      }
    })
    .catch(() => {
      console.log('Did you logged in yet?');
    });
  }

  onSetPlan(planId: string, planName: string): void {
    this.isLoading = true;
    setTimeout(() => {
      this.authService.setPlan(planId, planName);
      this.routerService.go('home');
    }, 1000);
  }

  async ngOnInit(): Promise<void> {
    this.authService.loggedInUser.asObservable()
    .subscribe(user => {
      if (user) {
        this.displayName = user.displayName;
      }
    });
  }
}
