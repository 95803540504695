export class LocalStorageHelper {
        getItem(item: string): any {
                return JSON.parse((localStorage.getItem(item)));
        }

        setItem(item: string, data: any): void {
                return localStorage.setItem(item, JSON.stringify(data));
        }

        removeItem(item): void {
                return localStorage.removeItem(item);
        }
}
