<div id="faq-wrapper">
    <h2 class="page-header">Questions <span>Answered</span></h2>

    <div class="faq-container">
        <h3>About our service:</h3>
        <ng-container *ngFor="let question of general; let i = index;">
            <mat-expansion-panel #generalFAQ hideToggle="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <mat-icon *ngIf="!generalFAQ.expanded">add_circle_outline</mat-icon>
                        <mat-icon *ngIf="generalFAQ.expanded">remove_circle_outline</mat-icon>

                        {{question.topic}}
                    </mat-panel-title>
                </mat-expansion-panel-header>

                {{question.answer}}

            </mat-expansion-panel>
        </ng-container>
    </div>

    <div class="faq-container">
        <h3>Subscriptions:</h3>
        <ng-container *ngFor="let question of subscription; let i = index;">
            <mat-expansion-panel #generalFAQ hideToggle="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <mat-icon *ngIf="!generalFAQ.expanded">add_circle_outline</mat-icon>
                        <mat-icon *ngIf="generalFAQ.expanded">remove_circle_outline</mat-icon>

                        {{question.topic}}
                    </mat-panel-title>
                </mat-expansion-panel-header>

                {{question.answer}}

            </mat-expansion-panel>
        </ng-container>
    </div>

    <div class="faq-container">
        <h3>Uploading:</h3>
        <ng-container *ngFor="let question of uploads; let i = index">
            <mat-expansion-panel #billingFAQ hideToggle="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <mat-icon *ngIf="!billingFAQ.expanded">add_circle_outline</mat-icon>
                        <mat-icon *ngIf="billingFAQ.expanded">remove_circle_outline</mat-icon>

                        {{question.topic}}
                    </mat-panel-title>
                </mat-expansion-panel-header>

                {{question.answer}}

            </mat-expansion-panel>
        </ng-container>
    </div>

    <div class="faq-container">
        <h3>Points:</h3>
        <ng-container *ngFor="let question of points; let i = index">
            <mat-expansion-panel #billingFAQ hideToggle="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <mat-icon *ngIf="!billingFAQ.expanded">add_circle_outline</mat-icon>
                        <mat-icon *ngIf="billingFAQ.expanded">remove_circle_outline</mat-icon>

                        {{question.topic}}
                    </mat-panel-title>
                </mat-expansion-panel-header>

                {{question.answer}}

            </mat-expansion-panel>
        </ng-container>
    </div>
</div>