import { Component } from '@angular/core';
import { trigger, state, animate, transition, style, keyframes } from '@angular/animations';
import { MessageService } from 'src/app/services/message.service';
import { MatDialog } from '@angular/material/dialog';
import { MessageDialogComponent } from 'src/app/directives/message-dialog/message-dialog';

export const left = trigger('left', [
    state('hidden', style({ opacity: 0 })),
    state('inactive', style({ transform: 'translateX(0)', opacity: 1 })),
    state('active', style({ transform: 'translateX(15%)', opacity: 1 })),
    transition('* => active', [
        animate('2s', keyframes([
            style({ transform: 'translateX(0)', opacity: 1 }),
            style({ transform: 'translateX(15%)' , opacity: 1 }),
            style({ transform: 'translateX(0)', opacity: 1 }),
            style({ transform: 'translateX(15%)', opacity: 1 }),
            style({ transform: 'translateX(0)', opacity: 1 }),
            style({ transform: 'translateX(15%)', opacity: 1  }),
        ]))
    ])
]);

export const fade = trigger('fade', [
    state('out', style({ opacity: 0 })),
    state('in', style({ opacity: 1 })),
    transition('out => in', [
        style({ opacity: 0 }),
        animate(1000)
    ]),
    transition(':enter', [
        style({ opacity: 0 }),
        animate(1000)
    ])
]);

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    animations: [fade, left]
})

export class HomeComponent {
    constructor(public messageService: MessageService, private dialog: MatDialog) {
        this.messageService.subscription.asObservable()
        .subscribe(data => {
          this.dialog.open(MessageDialogComponent, {
            width: '275px',
            panelClass: 'message-dialog',
            data,
            disableClose: data.disableClose
          });
        });
    }

    step1Done = false;
    step2Done = false;

    onDone($event, type): void {
        switch (type){
            case 'fadeOne':
                this.step1Done = true;
                break;

            case 'fadeTwo':
                this.step2Done = true;
                break;
        }
    }
}
