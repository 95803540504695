import { EventManager } from '@angular/platform-browser';
import { Injectable, EventEmitter } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ResponsiveService {
        public onResize$ = new EventEmitter<{ width: number; height: number; }>();

        constructor(eventManager?: EventManager) {
                if (eventManager) {
                        eventManager.addGlobalEventListener('window', 'resize',
                        e => this.onResize$.emit({
                                width: e.target.innerWidth,
                                height: e.target.innerHeight
                        }));
                }
        }
}
