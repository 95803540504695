import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'friendlyFilesize'
})
export class FriendlyFilesizePipe implements PipeTransform {

    transform(bytes: any, ...args: any[]): any {
        if (bytes === null || isNaN(bytes)) { return '0 KB'; }
        const si = args[0] ? args[0] : false;
        const thresh = si ? 1000 : 1024;
        if (Math.abs(bytes) < thresh) {
                return bytes + ' B';
        }
        const units = si
                ? ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
                : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
        let u = -1;
        do {
                bytes /= thresh;
                ++ u;
        } while (Math.abs(bytes) >= thresh && u < units.length - 1);
        return bytes.toFixed(0) + ' ' + units[u];
    }

}
