export const MessageConst = {
        status: {
                Success: 'Success !',
                Failed: 'Failed !',
                Warning: 'Warning !'
        },
        label: {
                auth: 'Authentication'
        },
        more: {
                LoginFailed: 'Your e-mail or password is incorrect. Please try again.'
        },
        networkBroken: 'A network error has occurred. Please try again later.',
        navigateConfirmation: 'Are you sure you wish to navigate away from this page? Your upload might not complete successfully.',
        pointsLess: 'You don\'t have enough points to upload at this time. Please add credits to your account to continue.',
        forbidden: 'You don\'t have permission to access this page.',
        tokenExpired: 'Your session has expired. Please login again to continue.',
        uploadError: 'There was a problem completing your upload. Please try again later.',
        pointsInvalidRequest: 'Please enter a number between 0 - 1000.',
        uploadErrorGeneral: 'Your upload was unable to be completed. Please make sure you are selecting a properly formatted PDF file with a ".pdf" extension (case sensitive).'
};
