export class Dialog {
    type: string;
    message: string;
    header?: string;
    data?: any;
    title?: string;
    toPath?: string;
    panelClass?: string;
    isPointLess?: boolean;
    isRefreshToken?: boolean;
    isForceLogout?: boolean;
    txtBtnLeft?: string;
    txtBtnRight?: string;
    disableClose?: boolean;
}
