import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MessageDialogComponent } from 'src/app/directives/message-dialog/message-dialog';
import { MessageService } from 'src/app/services/message.service';
import { UploaderService } from 'src/app/services/uploader.service';
import { UserInfoService } from 'src/app/services/user-info.service';

@Component({
  selector: 'app-uploads',
  template: `
    <div id="dashboard-page">
      <app-upload-histories></app-upload-histories>
    </div>
  `,
})
export class DashboardComponent implements OnInit, OnDestroy {
  constructor(
    public dialog: MatDialog,
    public userInfo: UserInfoService,
    public uploaderService: UploaderService,
    public messageService: MessageService) {
      this.messageService.subscription.asObservable()
        .subscribe(data => {
          this.dialog.open(MessageDialogComponent, {
            width: '275px',
            panelClass: 'message-dialog',
            data,
            disableClose: data.disableClose
          });
        });
  }

  async ngOnInit(): Promise<void> {
    this.uploaderService.getDocuments();
    // this.userInfo.getPoints();
  }

  ngOnDestroy(): void {
    this.uploaderService.resetPaginationOptions();
  }
}
