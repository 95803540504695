<div id="about-us_wrapper">
    <h2 class="page-header">About <span>Us</span></h2>

    <p class="highlight">We are a tight-knit team of digital artisans and software engineers, focused on making the 
    most reliable, scalable, and economical optical character recognition (OCR) solution for you.</p>
    
    <p>Born as a 
    cloud-native solution, the mission of QuiqText is to substantially improve the velocity of your workflow, 
    while drastically reducing costs.</p>

    <div class="row">
        <div id="data-info_wrapper" class="col s12">
            <div class="circle-bg">
                <div class="circle-bg_container">
                    <img src="../../../../assets/icon_lock.png" alt="lock icon" class="lock-icon" />
                    
                    <div class="about-section">
                        <h3>Your Data is Yours</h3>
                        <p>All uploaded and processed documents are deleted after a month from the date in which the document is processed. QuiqText 
                        does not retain any original or processed documents.</p>
                    </div>
                    <div class="about-section">
                        <h3>Your Information is Yours</h3>
                        <p>We do not sell, barter, nor share your data or any information about you.</p>
                    </div>
                </div>
            </div>
        </div>
   
        <div id="deployments-info_wrapper" class="col s12">
            <div class="about-section">
                <div>
                    <h3>
                        <span matBadge="1" matBadgePosition="after" matBadgeColor="accent"></span>
                        Phased deployments
                    </h3>
                    <p>QuiqText will be rolled out in phases of ever-increasing capabilities and power. The first phase is to simply introduce 
                    QuiqText to you - for free. In fact, you will always have a free subscription tier (up to 30 points) where you can perform 
                    small OCR tasks and we will replenish your free points each month. So do a favor for your friends and work colleagues and let 
                    them know about getting free OCR using QuiqText!</p>
                </div>
            </div>

            <div class="about-section row">
                <div class="offset-l1 col s12">
                    <h3>
                        <span matBadge="2" matBadgePosition="after" matBadgeColor="accent"></span>
                        Next Phase
                    </h3>
                    <p>Our next phase will be deployed with the ability to purchase points to supplement your free monthly points. 
                    These purchased points will never expire.</p>
                </div>
            </div>

            <div class="about-section">
                <div>
                    <h3>
                        <span matBadge="3" matBadgePosition="after" matBadgeColor="accent"></span>
                        Future Phases
                    </h3>
                    <p>Depending upon your interests and needs, we will be deploying more functions and features to include:</p>
                    <ul>
                        <li>Multiple Concurrent Uploads</li>
                        <li>RESTful API</li>
                        <li>Multi-user Subscription</li>
                        <li>Business Subscription</li>
                        <li>Structured Data Extraction</li>
                    </ul>
                    
                    <p>Let us know what functions or features you would like most and we’ll strongly consider it.</p>
                </div>
            </div>
        </div>
    </div>
</div>
