import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import isEqual from 'lodash.isequal';
import filter from 'lodash.filter';
import { BehaviorSubject, Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
import { ReportingInfo } from 'src/app/models/reporting-info.model';
import slsConfig from 'src/serverless-stack-output.json';

import { AuthService } from './auth.service';
import { UserInfoService } from './user-info.service';
import { Document } from '../models/document.model';

@Injectable()
export class UploaderService {
  public readBody;
  public endPoint;
  public sortState;
  public sortStates;
  public filterState;
  public jobsState;
  public isUploading;
  public errorData;
  public uploadStatus = new EventEmitter<boolean>();
  public reportingInfo = new BehaviorSubject<ReportingInfo>(null);
  public jobs = new BehaviorSubject<any[]>([]);
  public jobsPage = new BehaviorSubject<any[]>([]);
  public currentJobId = new BehaviorSubject<string>('');
  public pageSize = 10; // Default pagesize = 10

  public pageSizeOptions = new BehaviorSubject<number[]>([]);
  public pageIndex = 1;
  public interval: any;
  public tryCount = 0;
  public maxxTryCount = 20;

  public checkedDocuments = new BehaviorSubject<Document[]>([]);
  public recentUploadedId: string;

  constructor(
    public http?: HttpClient,
    public authService?: AuthService,
    private userInfoService?: UserInfoService,
    public dialog?: MatDialog,
  ) {
    this.endPoint = slsConfig.ServiceEndpoint;

    this.sortStates = [
      {
        key: 'file',
        isDown: true,
        isActived: false,
      },
      {
        key: 'size',
        isDown: true,
        isActived: false,
      },
      {
        key: 'created',
        isDown: true,
        isActived: true,
      },
    ];

    this.uploadStatus.asObservable().subscribe(isUploading => {
      this.isUploading = isUploading;
      if (!isUploading) {
        this.dialog.closeAll();
      }
    });

    this.jobs.asObservable()
    .subscribe(jobs => {
      this.pageSizeOptions.next(this.getPaginatorPageOptions(jobs.length));
      const splitedItems = filter(jobs, item => item.state === 'ocr-ing');
      const currentJobs = this.sort().slice(
        this.pageSize * this.pageIndex - this.pageSize,
        this.pageSize * this.pageIndex
      );
      // ---Creating filter to determine if job is past the erase_doc_dt date
      const todaysDate = new Date();
      const jobsNotExpired = filter(currentJobs, job => todaysDate.setHours(0, 0, 0, 0) <= new Date(job.erase_doc_dt).setHours(0, 0, 0, 0));

      this.jobsPage.next(jobsNotExpired);

      const downloadFiles = this.checkedDocuments.getValue();
      jobs.map(job => {
        const index = downloadFiles.findIndex(doc => doc.did === job.did);
        if (index < 0) { job.checked = false; }
        else { job.checked = true; }
      });

      // Check state = 'ocr-ing' items
      if (!splitedItems.length) {
        return (this.tryCount = this.maxxTryCount);
      } else { console.log(); }
      this.interval = setInterval(() => {
        this.tryCount++;
        if (this.tryCount >= this.maxxTryCount) {
          if (this.tryCount > (this.maxxTryCount + 1)) {
            clearInterval(this.interval);
            return;
          }
          this.userInfoService.getPoints();
          clearInterval(this.interval);
          return;
        }
        this.getDocuments();
      }, 20000);
    });
  }

  // API endpoint
  getUploadURL(file?: any, output?: any): Promise<any> {
    const sid = this.authService?.currentPlanID?.getValue() ?? '';
    return new Promise(async (resolve, reject) => {
      const apiURL = `${this.endPoint}/subscriptions/${sid}/documents`;
      const data = { filename: file.name, output };
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/pdf',
        })
      };
      this.http.post(apiURL, data, httpOptions)
      .toPromise()
      .then(res => resolve(res))
      .catch(error => console.log(error?.status));
    });
  }

  // API endpoint
  getDocuments(): Promise<any> {
    return new Promise(async (resolve) => {
      const sid = this.authService.currentPlanID.getValue();
      this.http
        .get(this.endPoint + `/subscriptions/${sid}/documents`)
        .toPromise()
        .then((res: any) => {
          if (res.data && res.data.documents) {
            const jobs: Document[] = res.data.documents.filter(doc => !doc.deleted_by && doc.state !== 'uploading');
            if (this.recentUploadedId) {
              const recentDocIndex = jobs.findIndex(j => j.did === this.recentUploadedId);
              if (recentDocIndex >= 0) {
                jobs[recentDocIndex].state = 'ocr-ing';
                this.recentUploadedId = null;
              }
            }
            this.jobs.next(jobs);
            this.jobsState = JSON.parse(JSON.stringify(jobs));

            resolve(true);
          } else {
            resolve(null);
          }
        })
        .catch(error => console.log(error?.status));
    });
  }

  // API endpoint
  async getDocumentDetails(did: string): Promise<void> {
    const currentJobs = this.jobs.getValue();
    const sid = this.authService.currentPlanID?.getValue();
    this.currentJobId.next(did);
    const index = currentJobs.findIndex(e => e.did === did);
    if (!currentJobs[index].details) {
      currentJobs[index].isLoading = true;
      this.jobs.next(currentJobs);
      // call API
      try {
        const res = await this.http.get(this.endPoint + `/subscriptions/${sid}/documents/${did}`).toPromise() as any;
        currentJobs[index].isLoading = false;
        if (res.data && res.data.document_info) {
          currentJobs[index].details = res.data.document_info;
        }
        this.jobs.next(currentJobs);
      } catch (error) {
        console.log(error?.status);
      }
    } else { console.log(); }
  }

  // API endpoint
  uploadFile(url: string, file: any): Promise<any> {
    return new Promise(async (resolve) => {
      const headers = {
        'Content-Type': 'application/pdf',
        'None-Auth': 'none'
      };
      const options = { headers };
      await this.waitForAsync(2000);
      if (this.isUploading) {
        this.http
          ?.put(url, file, options)
          .toPromise()
          .then(res => resolve(res))
          .catch(error => console.log(error?.status));
      } else {
        console.log('cannot upload now');
      }
    });
  }

  // Single download API endpoint
  downloadFile(file, type): Promise<void> {
    return new Promise(async (resolve, reject) => {
      const sid = this.authService.currentPlanID.getValue();
      const jobs = this.jobs.getValue();
      const index = jobs.findIndex(e => e.did === file.did);
      jobs[index].downloading = true;
      jobs[index].checked = true;
      this.jobs.next(jobs);
      try {
        const response = await this.http.get(this.endPoint + `/subscriptions/${sid}/documents/${file.did}/${type}`).toPromise() as any;
        if (response) {
          setTimeout(() => {
            const link = document.createElement('a');
            link.href = response.data[type === 'pdf' ? 'pdf_url' : 'json_url'];
            link.download = `${response.data.filename.split('.')[0]}.${type}`;
            document.body.appendChild(link);
            link.click();

            // clean up
            document.body.removeChild(link);
            link.remove();
            this.uncheckDocuments(file.did, type);
            jobs[index].downloading = false;
            jobs[index].downloaded_dt = true;
            this.jobs.next(jobs);
            resolve();
          }, 500);
        } else {
          resolve(null);
        }
      } catch (error) {
        console.log(error?.status);
      }
    });
  }

  // delete file API endpoint
  deleteFile(did): Promise<void | any> {
    return new Promise(async (resolve, reject) => {
      const sid = this.authService.currentPlanID.getValue();
      this.http
        .delete(this.endPoint + `/subscriptions/${sid}/documents/${did}`)
        .pipe(delay(1000))
        .toPromise()
        .then(() => {
          // delete in current jobs (pagination)
          const jobs = this.jobs.getValue();
          const index = jobs.findIndex(e => e.did === did);
          if (index >= 0) {
            jobs.splice(index, 1);
          }
          this.jobs.next(jobs);
          // delete in jobs state
          const globalIndex = this.jobsState.findIndex(e => e.did === did);
          if (globalIndex >= 0) {
            this.jobsState.splice(globalIndex, 1);
          }
          resolve({ did });
        })
        .catch(error => console.log(error?.status));
    });
  }

  // Logical function
  getJobs(): Observable<any> {
    return this.jobs.asObservable();
  }

  // Logical function
  updateJobs(jobs): void {
    return this.jobs.next(jobs);
  }

  getCheckedDocuments(): Observable<Document[]> {
    return this.checkedDocuments.asObservable();
  }

  addCheckedDocument(doc: Document): void {
    const list = this.checkedDocuments.getValue();
    const index = list.findIndex(e => e.did === doc.did);
    if (index >= 0) {
      list.splice(index, 1);
    } else {
      if (doc.state && doc.state.includes('error')) {
        doc.output_formats = [];
      }
      list.push(doc);
    }
    this.checkedDocuments.next(list);
  }

  // Logical function
  uncheckDocuments(did: string, type: 'json' | 'pdf'): any {
    const list = JSON.parse(JSON.stringify(this.checkedDocuments.getValue()));
    const indexesToDel = [];
    list.map((doc, i) => {
      if (doc.did === did) {
        const output_formats = doc.output_formats;
        const index = output_formats.findIndex(e => e === type);
        if (index >= 0) {
          output_formats.splice(index, 1);
          if (output_formats.length === 0) {
            indexesToDel.push(i);
          } else {
            console.log();
          }
        } else {
          console.log();
        }
      } else {
        console.log();
      }
    });

    if (indexesToDel.length) {
      indexesToDel.forEach(index => list.splice(index, 1));
    }
    this.updateCheckedDocuments(list);
  }

  updateCheckedDocuments(list: Document[]): void {
    return this.checkedDocuments.next(list);
  }

  // Logical function
  getJobsPage(): Observable<any> {
    return this.jobsPage.asObservable();
  }

  // Logical function
  getReportingInfo(): Observable<any> {
    return this.reportingInfo.asObservable();
  }

  // Logical function
  updatePageSize(pageSize: number, pageIndex: number): void {
    this.pageSize = pageSize;
    this.pageIndex = pageIndex;
    this.jobs.next(this.jobs.getValue());
  }

  // Logical function
  resetPaginationOptions(): void {
    this.pageSize = 10;
    this.pageIndex = 1;
  }

  // Logical function
  updateSortState(newState): void {
    this.sortState = newState;
    this.jobs.next(this.jobs.getValue());
  }

  // Logical function
  updateFilterState(tabIndex, isFromReporting?): void {
    switch (tabIndex) {
      case 0: // Default State
        this.filterState = null;
        break;
      case 1: // In-progress State or Downloaded State
        this.filterState = { state: 'ocr-ing' };
        if (isFromReporting) {
          this.filterState = { downloaded_dt: true };
        } else {
          console.log();
        }
        break;
      case 2: // Complete State or Deleted State
        this.filterState = { state: 'done' };
        if (isFromReporting) {
          this.filterState = { state: 'deleted' };
        } else {
          console.log();
        }
        break;
      case 3: // Error State
        this.filterState = {
          // state: 'handle user error' ||
          //   'handle_system_error' ||
          //   'done_error_user' ||
          //   'done_error_system' ||
          //   'uploaded' ||
          //   'DeductPointsJson',
          state: 'error'
        };
        break;
    }
    this.filter();
  }

  // Logical function
  sort(): any[] {
    const items = this.jobs.getValue();
    if (!this.sortState || this.sortState.key.includes('created')) {
      const sort = items.sort((a, b) => {
        const aCreated = new Date(a.uploaded_dt).getTime();
        const bCreated = new Date(b.uploaded_dt).getTime();
        if (!this.sortState) {
          return aCreated - bCreated;
        }
        if (!this.sortState.isDown) {
          return bCreated - aCreated;
        } else {
          return aCreated - bCreated;
        }
      });
      if (isEqual(items, sort)) {
        return sort.reverse();
      } else {
        return sort;
      }
    }
    else if (
      this.sortState.key.includes('file') ||
      this.sortState.key.includes('size')
    ) {
      const sort = items.sort((a, b) => {
        if (!this.sortState.isDown) {
          if (a[this.sortState.key] < b[this.sortState.key]) {
            return -1;
          }
          return 1;
        }
        if (this.sortState.isDown) {
          if (a[this.sortState.key] < b[this.sortState.key]) {
            return 1;
          }
          return -1;
        }
      });
      if (isEqual(items, sort)) {
        return sort.reverse();
      }
      return sort;
    } else {
      return [];
    }
  }

  // Logical function
  filter(jobs?: any): void {
    const filterState = JSON.parse(JSON.stringify(jobs ? jobs : this.jobsState));
    if (!this.filterState) {
      return this.jobs.next(filterState);
    }
    const filtered = filter(filterState, item => {
      if (this.filterState.state === 'ocr-ing' && item.state === 'ocr-ing') {
        return item;
      } else {
        console.log();
      }
      if (this.filterState.downloaded_dt && item.downloaded_dt) {
        return item;
      } else {
        console.log();
      }
      if (this.filterState.state === 'error') {
        return item.error;
      } else {
        console.log();
      }
      return item.state === this.filterState.state;
    });
    this.jobs.next(filtered);
  }

  // Logical function
  selectJob(index, isChecked): void {
    this.jobs.forEach(job => {
      if (job[index]) {
        job[index].checked = isChecked;
      } else {
        console.log('not do sth');
      }
    });
    this.jobs.next(this.jobs.getValue());
  }

  // Logical function
  getPaginatorPageOptions(jobLength: number): number[] {
    if (jobLength > 50) {
      return [10, 25, 50, 100];
    }
    if (jobLength > 25) {
      return [10, 25, 50];
    }
    if (jobLength > 10) {
      return [10, 25];
    }
    return [10];
  }

  // Logical function
  resetJobsToInit(): void {
    this.checkedDocuments.next([]);
    this.jobs.asObservable()
    .subscribe(job => {
      job.map(e => e.checked = false);
    })
    .unsubscribe();
  }

  // Logical function
  waitForAsync(miliseconds: number): Promise<void> {
    return new Promise(resolve => {
      setTimeout(() => resolve(), miliseconds);
    });
  }

  setRecentUploadedId(did: string): void {
    this.recentUploadedId = did;
  }
}
