<div id="home-wrapper">
    <h1>Quiq<span>Text</span></h1>

    <p>You upload a PDF.</p>

    <p class="highlight-wrapper">Our service breaks down your file page by page, extracts your document's text using OCR, and makes all pages <span class="highlight-text">text searchable.</span></p>

    <p>It's that easy.</p>

    <app-uploader></app-uploader>
</div>