<div id="upload-histories-wrapper">
    <h2 class="page-header">Track <span>Uploads</span></h2>

    <div id="multiple-downloads_row" *ngIf="jsonDownloads?.length || pdfDownloads?.length" [ngClass]="{'both-active': jsonDownloads?.length > 0 && pdfDownloads?.length > 0}">
        <button mat-menu-item class="mat-menu-item json-download" (click)="downloads('json')" *ngIf="jsonDownloads?.length">
            <fa-icon [icon]="faArrowDown" size="2x"></fa-icon>
            <fa-icon [icon]="faFileAlt" size="2x"></fa-icon>

            <div>
                <strong>Download</strong>
                <span>{{ jsonDownloads?.length }} JSON file<span *ngIf="jsonDownloads?.length > 1">s</span></span>
            </div>
        </button>

        <button mat-menu-item class="mat-menu-item pdf-download" (click)="downloads('pdf')" *ngIf="pdfDownloads?.length">
            <fa-icon [icon]="faArrowDown" size="2x"></fa-icon>
           <fa-icon [icon]="faFilePdf" size="2x"></fa-icon>

            <div>
                <strong>Download</strong>
                <span>{{ pdfDownloads?.length }} PDF file<span *ngIf="pdfDownloads?.length > 1">s</span></span>
            </div>
        </button>
    </div>

    <div class="upload-histories-result">
        <div [ngClass]="isLoading? 'hidden': 'visible'">
            <mat-tab-group [@.disabled]="true" (selectedIndexChange)="getTabIndex($event)" #uploadsTable [selectedIndex]="0">
                <mat-tab label="ALL" ></mat-tab>
                <mat-tab label="IN-PROGRESS" ></mat-tab>
                <mat-tab label="COMPLETE" ></mat-tab>
                <mat-tab label="FAILURES" ></mat-tab>
            </mat-tab-group>
        </div>

        <mat-spinner diameter=75 *ngIf="isLoading"></mat-spinner>
        <app-upload-item [uploads]="items" [hidden]="isLoading"></app-upload-item>
    </div>

    <div *ngIf="!items.length && !isLoading" id="no-uploads-exist_wrapper">
        <div class="no-uploads-msg">
            <mat-icon mat-list-icon>error</mat-icon>
            No uploads to show.
        </div>
    </div>
</div>