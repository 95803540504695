<div id="confirm-dialog">
    <div *ngIf="status === 'init'">
        <div class="deleting-previous">
            <div *ngIf="data.type === 'Warning'">
                <div class="header warning-header">
                    <img src="../../../assets/icon_warning.png" alt="warning icon" width="24" height="24" srcset="">
                    <span>Warning!</span>
                </div>
                <h5 class="warning-confirmation" *ngIf="data.isProcessing == false">You are attempting to delete an upload that has not been downloaded yet.</h5>
                <h5 class="warning-confirmation" *ngIf="data.isProcessing == true">You are attempting to cancel a job that has not finished processing.</h5>
                <h5 class="warning-confirmation" *ngIf="data.isProcessing == 'MULTI_DELETE'">You are attempting to delete {{ data.length }} jobs, some of which have not been downloaded yet.</h5>
                <h5 class="warning-confirmation">Do you wish to continue?</h5>
            </div>
            <h5 *ngIf="data.type !== 'Warning'">
                <ng-container *ngIf="data.isProcessing != 'MULTI_DELETE'">Are you sure you wish to delete the file: {{ data.filename }}?</ng-container>
                <ng-container *ngIf="data.isProcessing == 'MULTI_DELETE'">You are attempting to delete {{ data.length }} jobs. Do you wish to continue?</ng-container>
            </h5>
            <div class="dialog-buttons_container" >
                <button (click)="onConfirm('begin')" class="confirm mat-flat-button">Yes</button>
                <button (click)="onConfirm('cancle')" class="cancel mat-flat-button">No</button>
            </div>
        </div>
    </div>
    <div *ngIf="status === 'begin'">
        <div class="waiting">
            <h5>Deleting {{ data.isProcessing != 'MULTI_DELETE' ? data.filename : null }} </h5>
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
    </div>
    <div *ngIf="status === 'finish-success'">
        <div class="waiting">
            <h5>Deleted successfully! <span class="complete-icon"><img src="./assets/icon-complete_sm.png" width="24" height="24" alt="complete icon" /></span></h5>
        </div>
    </div>
    <div *ngIf="status === 'finish-error'">
        <div class="waiting">
            <h5>Error Occured! <span class="error-icon"><img src="./assets/icon_error.png" width="24" height="24" alt="error icon" /></span></h5>
        </div>
    </div>
    <div *ngIf="status === 'multi-delete-finish-error'">
        <div class="waiting multi-delete">
            <div class="head">
                <img src="./assets/icon_error.png" width="26" height="26" alt="error icon"/>
                <h5>Failed to delete {{ files.length > 1 ? "files:" : "the file:" }}</h5>
            </div>
            <div class="center" *ngFor="let file of files">{{file.file}}</div>
            <button class="cancel mat-flat-button mgt-15" (click)="onConfirm('cancle')">OK</button>
        </div>
    </div>
</div>