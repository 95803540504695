import { Component, Input , AfterViewInit, ViewChildren, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { UploaderService } from 'src/app/services/uploader.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDeleteComponent } from 'src/app/directives/confirm-dialog/confirm-delete.directive';
import { DownloadPromptComponent } from 'src/app/directives/download-prompt/download-prompt.component';
import { Subject } from 'rxjs';
import { UserInfoService } from 'src/app/services/user-info.service';
import slsConfig from 'src/serverless-stack-output.json';
import differenceBy from 'lodash.differenceby';
import { faArrowDown, faExclamation } from '@fortawesome/free-solid-svg-icons';
import { faFilePdf, faFileAlt } from '@fortawesome/free-regular-svg-icons';

@Component({
    selector: 'app-upload-item',
    templateUrl: './upload-item.component.html',
    styleUrls: ['./upload-item.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class UploadItemComponent implements AfterViewInit {
    @Input() filter: any;
    @Input() uploads: any = [];
    @Input() route: string;
    @ViewChildren('upload_available') uploadsAvailable;
    filterargs = {state: 'done'};
    itemsAvailableOnDom = new Subject();
    isDeleteStatus = false;
    endPoint = slsConfig.ServiceEndpoint;
    currentSortItem;
    dialogref;
    indexSwipeCurrent = null;
    sortItems;
    currentJobID = null;
    points;
    // MatPaginator Inputs
    length;
    pageSize;
    pageOptions;

    pointsReq: number;

    faArrowDown = faArrowDown;
    faFilePdf = faFilePdf;
    faFileAlt = faFileAlt;
    faExclamation = faExclamation;

    constructor(
        public uploadsService: UploaderService,
        private cdr: ChangeDetectorRef,
        public userInfo: UserInfoService,
        public downloadPrompt: MatDialog,
        public dialog: MatDialog) {
            this.dialog.afterAllClosed.subscribe(() => { this.isDeleteStatus = false; });
            this.uploadsService.tryCount = 0;
            this.sortItems = uploadsService.sortStates;
            this.currentSortItem = { ...this.sortItems[2] };
            this.points = this.userInfo.getUserInfo();
            this.pageSize = this.uploadsService.pageSize; // Observable object
            this.pageOptions = this.uploadsService.pageSizeOptions;
            this.uploadsService.getJobs().subscribe(jobs => {
                this.length = jobs.length;
            });
            this.uploadsService.currentJobId.asObservable().subscribe(id => this.currentJobID = id);
        }

    onGetDetail(doc: any): void {
        this.uploadsService.getDocumentDetails(doc.did);
    }

    onDelete(file, isProcessing): void {
        if (!this.isDeleteStatus) {
            this.onConfirmDelete(file, isProcessing);
        }
    }

    onDeletes(): void {
        const documents = this.uploadsService.checkedDocuments.getValue();
        const filtered = documents.filter(e => !e.downloaded_dt && !e.state.includes('error'));
        const length = documents.length;
        const isProcessing = length === 1 ? false : 'MULTI_DELETE';
        const type = filtered.length ? 'Warning' : 'Normal';
        const filename = documents[0].name;

        const subscription = this.dialog.open(ConfirmDeleteComponent, {
            width: '275px',
            disableClose: true,
            panelClass: 'cancel-upload-dialog',
            data: { type, length, filename, isProcessing },
            id: 'confirm-delete'
        }).componentInstance.isConfirmed;

        subscription.subscribe(async e => {
            if (e === 'begin') {
                const failedDeleteFiles = await this.asyncDeletes();
                if (failedDeleteFiles.length === 0) {
                    subscription.next('finish-success');
                } else {
                    subscription.next({
                        data: failedDeleteFiles,
                        status: 'multi-delete-finish-error'
                    });
                }
            } else {
                this.resetFiles();
            }
        });
    }

    onConfirmDelete(file: any, isProcessing): any {
        let type = '';
        if (!file.downloaded_dt && !file.state.includes('error')) { type = 'Warning'; }
        const subscription = this.dialog.open(ConfirmDeleteComponent, {
            width: '275px',
            disableClose: true,
            panelClass: 'cancel-upload-dialog',
            data: { type, isProcessing, filename: file.name },
        }).componentInstance.isConfirmed;

        subscription.subscribe(e => {
            if (e === 'begin') {
                this.uploadsService.deleteFile(file.did)
                .then(() => {
                    this.isDeleteStatus = false;
                    subscription.next('finish-success');
                })
                .catch(() => {
                    subscription.next('finish-error');
                    this.isDeleteStatus = false;
                });
            } else {
                this.resetFiles();
            }
        });
    }

    onSort(key, index): void {
        this.sortItems[index].isDown = !this.sortItems[index].isDown;
        if (this.currentSortItem.key === key) {
            this.currentSortItem = {
                ...this.sortItems[index]
            };
        } else {
            this.sortItems.forEach(item => {
                if (item.key !== key) {
                    item.isActived = false;
                } else {
                    item.isActived = true;
                    this.currentSortItem = {
                        ...item
                    };
                }
            });
        }
        this.uploadsService.updateSortState(this.currentSortItem);
    }

    download(upload, type, route): void {
        if (!route) {
            const downloadPromptDialog = this.downloadPrompt.open(DownloadPromptComponent, {
                width: '300px',
                disableClose: true,
                panelClass: 'download-prompt-wrapper',
                data: { ...upload, type }
            }).componentInstance.downloadStarted;
            downloadPromptDialog.next('init');

            this.uploadsService.downloadFile(upload, type).then(() => {
                downloadPromptDialog.next('download-successful');
                upload.downloaded_dt = true;
            });
        }
    }

    asyncDeletes = async () => {
        try {
            const promises = [];
            const filesToDel = this.uploadsService.checkedDocuments.getValue();
            filesToDel.forEach(file => {
                promises.push(this.uploadsService.deleteFile(file.did));
            });
            const deletedFiles = await Promise.all(promises);
            const failedDeleteFiles = differenceBy(filesToDel, deletedFiles, 'did');

            this.isDeleteStatus = false;
            this.resetFiles();
            return failedDeleteFiles;
        } catch (error) {
            return error;
        }
    }

	getWarningMsg(error, outputJSON, outputPDF): string {
        if (error.indexOf('Insufficient points') > -1){
            const points = error.match('points(.*)for');
            // --Extracting just the number value from the error message
            const pointsNum = points[1].trim().split('left: ');
            const pages = error.match('for(.*)page');

            if (outputJSON === true && outputPDF === true){
                this.pointsReq = Number(pages[1]) + Number(pages[1]);
            }
            else {
                this.pointsReq = Number(pages[1]);
            }

            // --If the points value isn't equal to 1, produce message appropriate for point(s)
            if (pointsNum[1] !== 1){
                return 'You only had ' + pointsNum[1] + ' points available when initiating this upload. You needed ' + this.pointsReq + ' to complete this upload. Please add more points to your account, and try again.';
            }
            else {
                return 'You only had ' + pointsNum[1] + ' point available when initiating this upload. You needed ' + this.pointsReq + ' to complete this upload. Please add more points to your account, and try again.';
            }
        }
        else {
            if (error.includes('AWS Textract failed: AWS Textract failed')) {
                return 'Quiqtext OCR failed';
            }
            return error;
        }
    }

    pageEvent(e): void {
        this.uploadsService.updatePageSize(e.pageSize, e.pageIndex + 1);
    }

    getCheckboxValue(item, index?): void {
        item.checked = !item.checked;
        this.uploadsService.addCheckedDocument(item);
    }

    resetFiles(): void {
        this.uploadsService.resetJobsToInit();
    }

    ngAfterViewInit(): void {
        this.uploadsAvailable.changes.subscribe(items => {
            this.itemsAvailableOnDom.next(items.length);
            this.cdr.detectChanges();
        });
    }
}
