import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FileUploader } from 'ng2-file-upload';
import { from } from 'rxjs';
import { delay } from 'rxjs/operators';
import { Enum } from 'src/app/constants/enum.constant';
import { MessageConst } from 'src/app/constants/message.constant';
import { CancelDialogDirective } from 'src/app/directives/cancel-dialog/dialog.directive';
import { MessageDialogComponent } from 'src/app/directives/message-dialog/message-dialog';
import { Dialog } from 'src/app/models/dialog.model';
import { Uploader } from 'src/app/models/uploader.model';
import { MessageService } from 'src/app/services/message.service';
import { UploaderService } from 'src/app/services/uploader.service';
import { UserInfoService } from 'src/app/services/user-info.service';

import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { faFileAlt } from '@fortawesome/free-regular-svg-icons';
import { UnlessPointsDialogComponent } from 'src/app/directives/unless-points-dialog/unless-points-dialog.component';
import { getDocument, GlobalWorkerOptions, version } from 'pdfjs-dist';
import { LocalStorageHelper } from 'src/app/helpers/local-storage.helper';

@Component({
    selector: 'app-uploader',
    templateUrl: './uploader.component.html',
    styleUrls: ['./uploader.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class UploaderComponent implements OnDestroy, OnInit {
    public fileOver = false;
    public uploadStatus;
    public res: Uploader;
    public uploader: FileUploader;
    private dialogErrorInfo: Dialog;
    readyToUpload = false;
    uploadFormats = [];
    chosenFormat;
    uploadPDF;
    uploadJSON;
    faFilePdf = faFilePdf;
    faFileAlt = faFileAlt;
    numPages = 0;
    uploadCost = 0;
    pointsLeft;
    cantUpload = false;
    numFormats = 0;
    localStorage = new LocalStorageHelper();

    constructor(
        private uploaderService: UploaderService,
        private messageService: MessageService,
        public dialog: MatDialog,
        public userInfoService: UserInfoService) {
            this.uploader = new FileUploader({
                queueLimit: 1,
                allowedFileType: ['pdf'],
            });
            this.uploaderService.uploadStatus.subscribe(isUploading => {
                if (!isUploading) {
                    this.uploader.clearQueue();
                    this.uploadStatus = 'init';
                }
            });
            this.dialogErrorInfo = {
                type: Enum.Error,
                message: MessageConst.uploadError,
            };
    }

    async ngOnInit(): Promise<any> {
        const value = await this.userInfoService.getPoints() as any;
        this.pointsLeft = value.data.subscription.points_left;
    }

    onChange(event): void {
        // --Clear out any previously saved calculations for upload cost
        this.resetUploadCalc();

        const pdfjsLib = require('pdfjs-dist/legacy/build/pdf.js');
        const pdfjsWorker = import('pdfjs-dist/build/pdf.worker.entry');

        const pdfWorkerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${version}/pdf.worker.min.js';
        pdfjsLib.GlobalWorkerOptions.workerSrc = pdfWorkerSrc;

        const file = event.target.files[0];
        const fileReader = new FileReader();

        fileReader.onload = () => {
            const typedarray = new Uint8Array(fileReader.result as ArrayBuffer);
            pdfjsLib.getDocument(typedarray).promise.then((doc) => {
                this.numPages = doc.numPages;
                event.srcElement.value = '';
            });

        };

        fileReader.readAsArrayBuffer(file);
    }

    resetForm(e): void {
        this.uploader.clearQueue();

        // --Clear out any previously saved calculations for upload cost
        this.resetUploadCalc();

        e.preventDefault();
    }

    resetUploadCalc(): void{
        // --Reset counts for uploader
        this.numPages = 0;
        this.uploadCost = 0;
        this.cantUpload = false;
        this.readyToUpload = false;
        this.uploadFormats = [];
    }

    selectUploadFormat(event, format): void {
        if (event.checked){
           this.uploadFormats.push(format);
        }

        if (!event.checked){
            const index = this.uploadFormats.indexOf(format);
            if (index > -1) {
                this.uploadFormats.splice(index, 1);
            }
        }

        // --Calculating the cost of an upload
        // --1 point per page, x the number of output formats
        // --Example: cost = 10 points for 1 PDF 5 pages long, 2 output formats
        this.numFormats = this.uploadFormats.length;
        this.uploadCost = Math.round(this.numPages * this.numFormats);

        if (this.uploadFormats.length === 0){
            this.readyToUpload = false;
        }
        else {
            // --If the cost of the upload exceeds points balance, disable form
            if (this.uploadCost > this.pointsLeft) {
                this.cantUpload = true;
            }
            // --Otherwise, enable form
            else {
                this.cantUpload = false;
                this.readyToUpload = true;
            }
        }
    }

    doUpload(): void {
        // --Initialize upload, show upload container + progress bar
        this.uploadStatus = 'begin';
        this.uploaderService.uploadStatus.next(true);

        // --Determining which upload format(s) to request for output
        if (this.uploadFormats.length > 1){
            this.chosenFormat = 'json,pdf';
        }
        else {
            this.chosenFormat = this.uploadFormats[0];
        }

        const file = this.uploader.queue[0].file.rawFile;
        // Read file, get presigned URL from API then PUT file to that S3 URL
        const getURL = from(this.uploaderService.getUploadURL(file, this.chosenFormat));
        getURL.pipe(delay(2000))
        .toPromise()
        .then(async (res: any) => {
            if (res && res.data.url) {
                try {
                    await this.uploaderService.uploadFile(res.data.url, file);
                    this.uploadStatus = 'complete';
                    const did = res.data.url.split('/')[8];
                    // const subId = localStorage.getItem('CurrentPlanID');
                    this.uploaderService.setRecentUploadedId(did);
                    setTimeout(() => {
                        this.uploadStatus = 'finish';
                        this.uploader.clearQueue();
                        this.uploaderService.uploadStatus.next(false);
                        this.uploadFormats = [];
                    }, 1000);
                } catch (error) {
                    // this.messageService.set(this.dialogErrorInfo);
                    this.uploadStatus = 'finish';
                    this.uploader.clearQueue();
                    this.uploaderService.uploadStatus.next(false);
                    this.uploadFormats = [];
                }
            }
        })
        .catch(error => {
            // this.messageService.set(this.dialogErrorInfo);
            this.dialog.open(UnlessPointsDialogComponent, {
                width: '275px',
                data: {
                    message: error.error.message,
                    txtBtnLeft: 'OK',
                },
                panelClass: 'cancel-upload-dialog'
            }).afterClosed().subscribe(e => {
                this.messageService.set(this.dialogErrorInfo);
                this.uploadStatus = 'error' || 'cancled';
                this.uploader.clearQueue();
                this.uploaderService.uploadStatus.next(false);
                this.uploadFormats = [];
            });
        });
    }

    // Use for drop file
    fileOverBase(e): void {
        this.fileOver = e;
        if (this.uploader.queue.length) {
            // --Clear out any previously saved calculations for upload cost
            this.resetUploadCalc();

            const pdfjsLib = require('pdfjs-dist/legacy/build/pdf.js');
            const pdfjsWorker = import('pdfjs-dist/build/pdf.worker.entry');

            const pdfWorkerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${version}/pdf.worker.min.js';
            pdfjsLib.GlobalWorkerOptions.workerSrc = pdfWorkerSrc;

            const file = this.uploader.queue[0]._file;
            const fileReader = new FileReader();

            fileReader.onload = () => {
                const typedarray = new Uint8Array(fileReader.result as ArrayBuffer);
                pdfjsLib.getDocument(typedarray).promise.then((doc) => {
                    this.numPages = doc.numPages;
                });

            };

            fileReader.readAsArrayBuffer(file);
        }
    }

    // --Cancel upload currently in progress
    cancelUpload(currentUpload, index): void {
        // --Open dialog, with preferences
        const dialogRef = this.dialog.open(CancelDialogDirective, {
                width: '275px',
                disableClose: true,
                data: {
                    dataKey: currentUpload, index
                },
                panelClass: 'cancel-upload-dialog'
        });

        // --Listen for confirmation event in dialog (user clicks 'Yes')
        dialogRef.componentInstance.onAdd.subscribe(() => {
            this.uploader.cancelAll();
            this.uploader.clearQueue();
            this.uploaderService.uploadStatus.next(false);
        });
    }

    uploadTesting(file: any): boolean {
        return true;
    }

    trackByFn(index, item): number {
        return index;
    }

    ngOnDestroy(): void {
        this.uploaderService.uploadStatus.next(false);
        this.uploader.cancelAll();
        this.uploader.destroy();
    }
}
