import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Error } from 'src/app/models/error.model';
import { MessageService } from './message.service';
import { Dialog } from 'src/app/models/dialog.model';
import { Enum } from 'src/app/constants/enum.constant';
import { MessageConst } from 'src/app/constants/message.constant';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandleService {
  public subscription = new Subject<Error>();
  public dialogData: Dialog = {
    type: Enum.Error,
    panelClass: 'error-message-dialog',
    message: '',
    txtBtnLeft: '',
    txtBtnRight: 'OK',
    disableClose: false,
  };
  constructor(public messageService?: MessageService) {
    this.subscription.asObservable()
    .subscribe(error => {
      this.dialogData.type = Enum.Error;
      switch (error.status) {
        case 502:
        case 501:
        case 500:
        case 400:
        case 401:
        case 0:
          let message = error.error.message;
          this.dialogData.header = 'Error';
          this.dialogData.txtBtnLeft = 'OK';
          this.dialogData.txtBtnRight = '';
          // override message
          if (message?.includes('sid could not find')) {
            message = 'Your subscription could not be found';
            this.dialogData.isForceLogout = true;
            this.dialogData.txtBtnLeft = 'Sign in again';
          }
          this.dialogData.message = message;
          this.messageService.set(this.dialogData);
          break;
        case 403:
          if (error.message?.includes('points')) { break; }
          this.dialogData.header = 'Forbidden';
          this.dialogData.message = MessageConst.forbidden;
          this.messageService.set(this.dialogData);
          break;
        case 402:
          if (error.error.indexOf('points to upload') > -1) {
            this.dialogData.header = 'Upload Error';
            this.dialogData.message = MessageConst.pointsLess;
            this.dialogData.isPointLess = true;
            this.dialogData.txtBtnLeft =  'Add Points',
            this.dialogData.txtBtnRight = 'OK',
            this.dialogData.toPath = '/Uploads',
            this.messageService.set(this.dialogData);
          }
          break;
        default:
          break;
      }
    });
  }

  setError(error: Error): void {
    return this.subscription.next(error);
  }
}
