import { Component, Inject, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-confirm-delete',
    styleUrls: ['confirm-delete.directive.scss'],
    templateUrl: 'confirm-delete.directive.html'
})
export class ConfirmDeleteComponent {
    public isConfirmed = new EventEmitter<any>();
    status;
    files = [];
    constructor(
        public dialogRef: MatDialogRef<ConfirmDeleteComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any ){
            this.isConfirmed.subscribe(e => {
                if (!e.data) {
                    this.status = e;
                    if (e.includes('finish')) {
                        this.dialogRef.close();
                    } else {
                        console.log('');
                    }
                    if (e.includes('cancle')) {
                        this.dialogRef.close();
                    } else {
                        console.log('');
                    }
                } else {
                    this.status = e.status;
                    this.files = e.data;
                }
            });
            this.isConfirmed.next('init');
        }

    onConfirm(isConfirmed: string): void {
        if (isConfirmed === 'CLOSE') {
            this.dialogRef.close();
        } else {
            console.log();
        }
        this.isConfirmed.emit(isConfirmed);
    }
}
