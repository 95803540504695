import { Component, Inject, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.directive.html',
    styleUrls: ['./dialog.directive.scss']
})
// tslint:disable-next-line:component-class-suffix
export class CancelDialogDirective {
    onAdd = new EventEmitter();
    constructor(
        public dialogRef: MatDialogRef<CancelDialogDirective>,
        @Inject(MAT_DIALOG_DATA) public data: any) {}


    continueCancel(): void {
        this.dialogRef.close();
        this.onAdd.emit(this.data);
    }

    abortCancel(): void {
        this.dialogRef.close();
    }
}
