<div id="upload-item" [hidden]="(itemsAvailableOnDom | async ) === ( false || null )">
    <div class="head" [hidden]="!uploads.length">
        <div class="row">
            <div class="thumb col pdl-80" [ngClass]="{'active': sortItems[0].isActived}">
                <span (click)="onSort('file',0)">FILENAME
                    <img src="./assets/icon_arrow-down.png" *ngIf="sortItems[0].key !== 'file'" width="20" height="20"
                        alt="sort arrow" />
                    <img src="./assets/icon_arrow-down.png" *ngIf="sortItems[0].key === 'file' && sortItems[0].isDown"
                        width="20" height="20" alt="sort arrow" />
                    <img src="./assets/icon_arrow-up.png" *ngIf="sortItems[0].key === 'file' && !sortItems[0].isDown"
                        width="20" height="20" alt="sort arrow" />
                </span>
            </div>
            <div class="size col web-view" [ngClass]="{'active': sortItems[1].isActived}">
                <span (click)="onSort('size',1)">SIZE
                    <img src="./assets/icon_arrow-down.png" *ngIf="sortItems[1].key !== 'size'" width="20" height="20"
                        alt="sort arrow" />
                    <img src="./assets/icon_arrow-down.png" *ngIf="sortItems[1].key === 'size' && sortItems[1].isDown"
                        width="20" height="20" alt="sort arrow" />
                    <img src="./assets/icon_arrow-up.png" *ngIf="sortItems[1].key === 'size' && !sortItems[1].isDown"
                        width="20" height="20" alt="sort arrow" />
                </span>
            </div>
            <div class="created col" [ngClass]="{'active': sortItems[2].isActived}">
                <span (click)="onSort('created',2)">UPLOADED
                    <img src="./assets/icon_arrow-down.png" *ngIf="sortItems[2].key !== 'created'" width="20"
                        height="20" alt="sort arrow" />
                    <img src="./assets/icon_arrow-down.png"
                        *ngIf="sortItems[2].key === 'created' && sortItems[2].isDown" width="20" height="20"
                        alt="sort arrow" />
                    <img src="./assets/icon_arrow-up.png" *ngIf="sortItems[2].key === 'created' && !sortItems[2].isDown"
                        width="20" height="20" alt="sort arrow" />
                </span>
            </div>
            <div class="removes col" *ngIf="(uploadsService.getCheckedDocuments() | async) as documents">
                <span class="icon delete" [ngClass]="{'multi-downloads': documents?.length > 1}">
                    <img class="pointer" (click)="onDeletes()" src="./assets/document-delete.png" width="18" height="24"
                        alt="delete icon" />
                </span>
            </div>
        </div>
    </div>

    <div class="body upload-availables">
        <ng-container *ngFor="let upload of uploads; let i = index">
            <div>
                <div #upload_available class="row d-flex-column"
                    [ngClass]="{'in-progress': upload.state === 'ocr-ing', 'download-row': upload.state === 'done' || upload.state === 'downloaded', 'view-details': upload.isLoading === false && upload.did === currentJobID}">
                    <div class="row-container">
                        <div class="checkbox" [ngClass]="{'checked': upload.checked}">

                            <div *ngIf="upload.state !== 'done' && upload.state !== 'downloaded'">
                                <div class="in-progress-icon" *ngIf="upload.state === 'ocr-ing'"></div>
                            </div>

                            <mat-checkbox id="checkbox{{i}}" [checked]="upload.checked"
                                (change)="getCheckboxValue(upload, i)" *ngIf="!upload.downloading && upload.state !== 'ocr-ing'">
                            </mat-checkbox>

                            <img class="icon inprogress-icon margin-7" src="./assets/downloading.gif"
                                *ngIf="upload.downloading" width="24" height="24" alt="downloading icon" />
                        </div>

                        <div class="col thumb d-flex-center"
                            *ngIf="upload.state === 'done' || upload.state === 'downloaded'" (click)="onGetDetail(upload)">
                            <ng-container *ngIf="!route">
                                <span *ngIf="(points | async) as points" class="upload-state_icon mgr-20 download-types"
                                    [ngClass]="{'unless-points': !points.points}">

                                    <button mat-button [matMenuTriggerFor]="menu" class="download-btn">
                                        <fa-icon [icon]="faArrowDown" size="2x"></fa-icon>
                                    </button>

                                    <mat-menu id="mat-menu" #menu="matMenu">
                                        <button mat-menu-item class="mat-menu-item"
                                            (click)="download(upload, 'json', route)"
                                            *ngIf="upload.output_formats.includes('json')">
                                            <fa-icon [icon]="faFileAlt" size="2x"></fa-icon>
                                            <span>Download JSON </span>
                                        </button>
                                        <button mat-menu-item class="mat-menu-item" (click)="download(upload, 'pdf', route)"
                                            *ngIf="upload.output_formats.includes('pdf')">
                                            <fa-icon [icon]="faFilePdf" size="2x"></fa-icon>
                                            <span>Download PDF</span>
                                        </button>
                                    </mat-menu>
                                </span>
                            </ng-container>

                            <span class="mat-icon-upload" *ngIf="route === 'reporting' && upload.state === 'done'">
                                <mat-icon>done_outline</mat-icon>
                            </span>

                            <span class="mat-icon-upload downloaded"
                                *ngIf="route === 'reporting' && upload.state === 'downloaded'">
                                <mat-icon>save_alt</mat-icon>
                            </span>

                            <span class="document mgr">
                                <fa-icon [icon]="faFilePdf" size="2x"></fa-icon>
                            </span>

                            <span class="filename">{{ upload.name }}<span *ngIf="upload.pages"
                                    class="pages">{{upload.pages}} pages</span></span>
                            <span class="upload-size"><span *ngIf="upload.pages" class="pages">{{upload.pages}}
                                    pages,</span> {{ upload.size | friendlyFilesize : true }}</span>
                        </div>

                        <div class="col thumb d-flex-center"
                            *ngIf="upload.state !== 'done' && upload.state !== 'downloaded'" (click)="onGetDetail(upload)">

                            <!-- <span class="upload-state_icon error mgr-20 in-progress_icon" *ngIf="upload.inprogress">
                                <img class="icon" src="./assets/icon_upload-in-progress.gif" alt="upload in progress" />
                            </span> -->

                            <span class="document mgr">
                                <fa-icon [icon]="faFilePdf" size="2x"></fa-icon>
                            </span>

                            <span class="filename">
                                <span class="name">{{ upload.name }}</span>
                                <span *ngIf="upload.pages" class="pages">{{upload.pages}} pages</span>
                            </span>
                            <span class="upload-size"><span *ngIf="upload.pages" class="pages">{{upload.pages}}
                                    pages,</span> {{ upload.size | friendlyFilesize : true }}</span>
                        </div>

                        <div class="col size web-view" (click)="onGetDetail(upload)">
                            <span class="value">{{ upload.size | friendlyFilesize : true }}</span>
                        </div>

                        <div class="col created" (click)="onGetDetail(upload)">{{ upload.uploaded_dt | datetime | date:'M/dd/yyyy' }}</div>
                        <div class="col remove d-flex-center-center" *ngIf="!route">
                            <div id="upload-status_col"><div *ngIf="upload.state !== 'done' && upload.state !== 'downloaded'">
                                <span class="upload-status-icon error-icon"
                                    *ngIf="upload?.error?.includes('Insufficient points')"
                                    [matTooltip]="getWarningMsg(upload?.error)" [matTooltipPosition]="'above'"
                                    aria-label="Button that displays a tooltip in various positions"
                                    [matTooltipClass]="{ 'tooltip': true }">
                                    <fa-icon [icon]="faExclamation" size="2x"></fa-icon>
                                </span>

                                <span class="upload-status-icon error-icon"
                                    *ngIf="upload.state.includes('error') && !upload?.error?.includes('Insufficient points')"
                                    [matTooltip]="getWarningMsg(upload?.error)" [matTooltipPosition]="'above'"
                                    aria-label="Button that displays a tooltip in various positions"
                                    [matTooltipClass]="{ 'tooltip': true }">
                                    <fa-icon [icon]="faExclamation" size="2x"></fa-icon>
                                </span>
                            </div></div>
                            <span class="delete" (click)="onDelete(upload, false)"
                                *ngIf="upload.state !== 'ocr-ing' || upload.state !== 'splitted' && upload.state !== 'ocr-ing'">
                                <img class="pointer" src="./assets/document-delete.png" width="18" height="24"
                                    alt="delete icon" />
                            </span>
                            <span class="delete" (click)="onDelete(upload, true)"
                                *ngIf="upload.state === 'ocr-ing' || upload.state === 'splitted'">
                                <img class="pointer" src="../../../../assets/icon-close.png" width="24" height="24"
                                    alt="close icon" />
                            </span>
                        </div>
                    </div>
                    <div class="row-container" *ngIf="upload.did === currentJobID">
                        <div class="loading-details" *ngIf="upload.isLoading">
                            <div class="loader-container">
                                <div class="loader"></div>
                            </div>
                        </div>
                        <div class="details-container" *ngIf="!upload.isLoading && upload.details">
                        <div class="details-item">
                            <strong>Points used:</strong> {{ upload?.details?.points_used }}
                        </div>
                        <div class="details-item">
                            <strong>Output format<span *ngIf="upload?.details?.output_formats.length > 1">s</span>:</strong> {{ upload.details.output_formats }}
                        </div>

                        <div class="details-item" *ngIf="upload?.error?.includes('Insufficient points')">
                            <strong>Upload error:</strong> Insufficient points
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>

    <mat-paginator *ngIf="length && length > 10" [length]="length" [pageSize]="pageSize"
        [pageSizeOptions]="pageOptions | async" (page)="pageEvent($event)">
    </mat-paginator>
</div>