<div id="credit-form" class="credit-available-wrapper">
    <div id="points-wrapper">
        <div id="points-container">
            <div id="points-header" class="points-label">Points:</div>
            <div class="cash-count center">
                <ng-container>
                    <div id="points-left_wrapper" class="points-col">
                        <div id="points-left">{{ pointsLeft }}</div>
                        <div class="points-label">Left</div>
                    </div>

                    <div id="points-divider">/</div> 
                    <div id="points-used_wrapper" class="points-col">
                        <div id="points-used">{{ pointsUsed }}</div>
                        <div class="points-label">Used</div>
                    </div>
                </ng-container>

                <!-- <div id="wave" *ngIf="isLoading">
                    <span class="dot"></span>
                    <span class="dot"></span>
                    <span class="dot"></span>
                </div>
                <ng-container *ngIf="!isLoading">
                    <div>{{ pointsInfo }}</div>
                </ng-container> -->
            </div>
        </div>
    </div>
    
    <!-- <div class="caption" *ngIf="addMore">
        <div class="center">Points allow you to access documents you have uploaded.</div>
        <div class="center">To add more to your account,&nbsp;<a (click)="showPointsForm = true">click here.</a></div>
    </div> -->

    <!-- <div id="add-points_wrapper" *ngIf="showPointsForm">
        <mat-form-field hintLabel="Choose a value between 0-1000." appearance="fill">
            <mat-label>Enter desired points:</mat-label>
            <input matInput (keydown.enter)="updatePoints(pointsInput.value)" type="number" placeholder="0" #pointsInput>
        </mat-form-field>
    </div> -->
</div>