import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-cookie-policy',
	templateUrl: './cookie-policy.component.html',
	styleUrls: ['./cookie-policy.component.scss']
})
export class CookiePolicyComponent implements OnInit {
	public scrollspyData = {
		sectionId: 'introduction',
		parentId: null
	};

	// -- Data for scrollspy navigation menu
  	// -- is_parent: assigned to a parent navigation item
  	// -- is_child: assigned to child of a parent navigation item
	// -- parent: the id of the parent navigation item
	public scrollContent = [
		{id: 'introduction', title: 'Cookie Policy'},
		{id: 'cookies-desc', title: 'What is a cookie?'},
		{id: 'cookie-types', title: 'Types of cookies', is_parent: true},
		{id: 'cookie-types-1', title: 'Performance cookies', is_child: true, parent: 'cookie-types'},
		{id: 'cookie-types-2', title: 'Functionality cookies', is_child: true, parent: 'cookie-types'},
		{id: 'cookie-types-3', title: 'Targeting/advertising cookies', is_child: true, parent: 'cookie-types'},
	];

	constructor() { }

	ngOnInit(): void {
		// --On page load, forcing target redirect to top of page
		document.querySelector('body').scrollIntoView({ block: 'start', behavior: 'smooth' });
	}

	onSectionChange(data): void {
		this.scrollspyData = data;
	}

	// --On click behavior for scrollspy links
	scrollTo(section): void {
		document.querySelector('#' + section).scrollIntoView({ block: 'start', behavior: 'smooth' });

		this.scrollspyData.sectionId = section;
	}
}
