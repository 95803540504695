import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Auth, { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Hub } from 'aws-amplify';
import { BehaviorSubject, Subject } from 'rxjs';
import { LocalStorageHelper } from 'src/app/helpers/local-storage.helper';
import { Cognito } from 'src/app/models/cognito.model';
import slsConfig from 'src/serverless-stack-output.json';

import { User } from '../models/user.model';

@Injectable({ providedIn: 'root' })
export class AuthService {
  public loggedInUser = new BehaviorSubject<User>(null);
  public cognito = new BehaviorSubject<Cognito>(null);
  public isAuthorizedObs = new Subject<boolean>();
  public localstorageHelper = new LocalStorageHelper();
  public user: User;
  public authorizedStatus: string;
  public payload;
  public endPoint = slsConfig.ServiceEndpoint;
  public currentPlan = new BehaviorSubject<string>('');
  public currentPlanID = new BehaviorSubject<string>('');

  constructor(private http?: HttpClient) {
    this.onInit();
    this.onGetCurrentUser();
    this.onListenHub();
    this.getPlan();
  }

  async onGetCurrentUser(): Promise<any> {
    try {
      const user = await Auth.currentAuthenticatedUser();
      this.user = {
        token: user.signInUserSession.accessToken.jwtToken,
        email: user.attributes.email,
        displayName: user.attributes.name,
        picture: this.getUserPicture(user.attributes.picture),
      };
      this.payload = user.signInUserSession.accessToken.payload;
      this.loggedInUser.next(this.user);
      this.localstorageHelper.setItem('AuthorizeStatus', 'LoggedIn');
      this.localstorageHelper.setItem('User', this.user);
      this.cognito.next({ status: 'LoggedIn', user });
    } catch (error) {
      if (this.authorizedStatus !== 'Checking') {
        this.localstorageHelper.setItem('AuthorizeStatus', 'LoggedOut');
        this.localstorageHelper.removeItem('User');
        this.cognito.next({ status: 'LoggedOut', user: null });
      } else {
        this._handleBackFromLoginInput();
      }
    }
  }

  onLogin(provider: string): void {
    this.cognito.next({ status: 'Checking', user: null });
    this.localstorageHelper.setItem('AuthorizeStatus', 'Checking');
    Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider[provider],
    });
  }

  onLogout(): void {
    Auth.signOut({ global: true });
    this.cognito.next({ status: 'Checking', user: null });
    this.localstorageHelper.setItem('AuthorizeStatus', 'LoggedOut');
    this.localstorageHelper.removeItem('User');
    this.localstorageHelper.removeItem('authorized_request');
    this.localstorageHelper.removeItem('current_route');
    this.localstorageHelper.removeItem('CurrentPlan');
    this.localstorageHelper.removeItem('CurrentPlanID');
  }

  onListenHub(): void {
    Hub.listen('auth', (e) => {
      this.onGetCurrentUser();
    });
  }

  _handleBackFromLoginInput(): void {
    if (this.localstorageHelper.getItem('authorized_request')) {
      this.localstorageHelper.setItem('AuthorizeStatus', 'LoggedOut');
      this.localstorageHelper.removeItem('authorized_request');
      this.cognito.next({ status: 'LoggedOut', user: null });
      return;
    }
    setTimeout(() => {
      if (this.authorizedStatus === 'Checking') {
        this.localstorageHelper.setItem('authorized_request', new Date());
        this.onGetCurrentUser();
      }
    }, 6000);
  }

  async onCheckValidToken(): Promise<any> {
    try {
      const currentSession = await Auth.currentSession();
      return currentSession.isValid();
    } catch (error) {
      return false;
    }
  }

  async onGetToken(): Promise<any> {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const token = user.signInUserSession.accessToken.jwtToken;
      return token;
    } catch (error) {
      return null;
    }
  }

  getLoggedInUser(): BehaviorSubject<User> {
    return this.loggedInUser;
  }

  getUserPicture(picture): string {
    try {
      // Handle to parse the picture for Facebook login
      const parser = JSON.parse(JSON.stringify(picture));
      const superParser = JSON.parse(parser);
      return superParser.data.url;
    } catch (error) {
      // Handle to parse the picture for Google / Amazon login
      return picture;
    }
  }

  getSubscriptions(): Promise<any> {
    try {
      const apiURL = `${this.endPoint}/subscriptions`;
      return this.http.get(apiURL).toPromise();
    } catch (error) {
      return error;
    }
  }

  getPlan(): void {
    const currentPlan = this.localstorageHelper.getItem('CurrentPlan');
    const currentPlanID = this.localstorageHelper.getItem('CurrentPlanID');
    if (currentPlan) { this.currentPlan.next(currentPlan); }
    if (currentPlanID) { this.currentPlanID.next(currentPlanID); }
    return null;
  }

  setPlan(planId: string, planName: string): void {
    this.currentPlan.next(planName);
    this.currentPlanID.next(planId);
    this.localstorageHelper.setItem('CurrentPlan', planName);
    this.localstorageHelper.setItem('CurrentPlanID', planId);
  }

  async onInit(): Promise<void> {
    this.authorizedStatus = this.localstorageHelper.getItem('AuthorizeStatus');
    this.cognito.next({ status: this.authorizedStatus, user: null });
  }
}
