<div id="download-dialog">
    <ng-container *ngIf="!isMultiDownloads">
        <div *ngIf="status === 'init'">
            <h5>Downloading <span>{{ data.file | convertFiletype : data.type }}</span></h5>
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <div *ngIf="status === 'download-successful'">
            <div class="waiting">
                <h5>Download for <span>{{ data.file | convertFiletype : data.type }}</span> completed successfully!</h5>
            </div>
        </div>
        <div *ngIf="status === 'download-unsuccessful'">
            <div class="waiting">
                <h5>There was a problem downloading <span>{{ data.file | convertFiletype : data.type }}</span>.</h5>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="isMultiDownloads">
        <div *ngIf="status === 'init'">
            <h5>Downloading ...</h5>
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <div *ngIf="status === 'download-successful'">
            <div class="waiting">
                <h5>Download successfully!</h5>
            </div>
        </div>
    </ng-container>
</div>