<div id="message-dialog">
    <mat-dialog-content>
        <div class="title">
            <img src="../../../assets/icon_warning.png" width="26" height="26" alt="error icon" *ngIf="data.type === 'Error'">
            <img src="../../../assets/icon_warning.png" width="24" height="24" alt="warning icon" *ngIf="data.type === 'Warning'">
            <span *ngIf="data.type !== 'Confirm'">
                {{ data.header || data.type }}
            </span>
        </div>
        <div class="message"><b>{{ data.message }}</b></div>
    </mat-dialog-content>
    <mat-dialog-actions class="fx-center">
        <button class="confirm mat-flat-button fx-center mgr-5" (click)="onConfirm(true)" *ngIf="data.txtBtnLeft != ''">{{ data.txtBtnLeft || 'YES' }}</button>
        <button class="cancel mat-flat-button fx-center" (click)="onConfirm(false)" *ngIf="data.txtBtnRight">{{ data.txtBtnRight }}</button>
    </mat-dialog-actions>
</div>