<div id="login-wrapper">
    <div id="login-container">
        <div id="login-form">
            <img src="/assets/logo_light.png" width="120" alt="quiqtext logo" class="logo-img responsive-img" />

            <h2>Quiq<span>Text</span></h2>

            <div class="header">
                <h4>Welcome back, <span>{{displayName}}</span></h4>
            </div>

            <h6>Select your subscription:</h6>
            <img *ngIf="isLoading" alt="loading animation" src="../../../assets/downloading.gif" />

            <ng-container *ngIf="!isLoading">
                <div *ngFor="let subscription of subscriptions" class="btn-choose-plan" (click)="onSetPlan(subscription.sid, subscription.plan)">
                    <span class="free">{{subscription.plan.toUpperCase()}}</span>
                    <span class="balance">Points Balance:</span>
                    <span class="value">{{subscription.points_left}}</span>
                </div>
            </ng-container>
        </div>
    </div>
</div>
