import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Dialog } from 'src/app/models/dialog.model';
import { MessageService } from 'src/app/services/message.service';
import { Router } from '@angular/router';
import { UploaderService } from 'src/app/services/uploader.service';
import { UserInfoService } from 'src/app/services/user-info.service';
import { AuthService } from 'src/app/services/auth.service';
import { RouterService } from 'src/app/services/router.service';

@Component({
    selector: 'app-message-dialog',
    templateUrl: './message-dialog.html',
    styleUrls: ['./message-dialog.scss']
})
export class MessageDialogComponent {
    constructor(
        public dialogRef?: MatDialogRef<MessageDialogComponent>,
        private messageService?: MessageService,
        private creditService?: UserInfoService,
        private routerService?: RouterService,
        private uploaderService?: UploaderService,
        private authService?: AuthService,
        @Inject(MAT_DIALOG_DATA) public data?: Dialog
        ){
    }

    onConfirm(isConfirmed): void {
        if (isConfirmed) {
            if (this.data.toPath) {
                this.routerService.navigate(this.data.toPath);
            } else {
                console.log();
            }
            if (this.data.isPointLess) {
                this.uploaderService.uploadStatus.next(false);
                this.creditService.isShowPointsForm = true;
                this.routerService.navigate(this.data.toPath);
            } else {
                console.log();
            }
            if (this.data.isRefreshToken) {
                // window.location.href = '';
            } else {
                console.log();
            }
            if (this.data.isForceLogout) {
                this.authService.onLogout();
            } else {
                console.log();
            }
        } else {
            console.log();
        }
        if (this.data.header === 'Upload Error') {
            this.uploaderService.uploadStatus.next(false);
        } else {
            console.log();
        }
        this.messageService.close();
    }
}
