<div id="unless-points-dialog">
    <mat-dialog-content>
        <div class="header">
            <img src="../../../assets/icon_warning.png" width="24" height="24" alt="warning icon">
            <span>
                Upload Error
            </span>
        </div>
        <div class="message"><b>{{ data.message }}</b></div>
    </mat-dialog-content>
    <mat-dialog-actions class="actions">
        <button class="confirm mat-flat-button mgr-5" (click)="onConfirm(true)" *ngIf="data.txtBtnLeft != ''">{{ data.txtBtnLeft || 'YES' }}</button>
        <button class="cancel mat-flat-button" (click)="onConfirm(false)" *ngIf="data.txtBtnRight">{{ data.txtBtnRight }}</button>
    </mat-dialog-actions>
</div>