<ul id="app-menu">
    <div id="nav-logo_container">
        <img src="/assets/logo_quiq-sm_light.png" width="60" height="50" class="logo" id="logo_light" alt="quiqtext logo" />
        <img src="/assets/logo_quiq-sm_dark.png" width="60" height="50" class="logo" id="logo_dark" alt="quiqtext logo" />
        <h1>Quiq<span>Text</span></h1>
    </div>
    <ng-container *ngIf="isLoggedIn | async">
        <div id="welcome-user">
            <img src="{{ auth?.user?.picture }}" *ngIf="auth?.user?.picture" alt="user profile picture" width="39" height="39" />
            <div class="welcome-txt name"><strong>{{ auth?.user?.displayName }}</strong></div>
            <div id="current-plan">{{currentPlan | async}} subscription</div>
        </div>

        <li (click)="toggleMenu('/home')" [ngClass]="{'route-active': isActive('home')}">
            <mat-icon mat-list-icon>cloud_upload</mat-icon>
            <div class="nav-title" mat-line>Upload</div>
        </li>

        <li (click)="toggleMenu('/dashboard')" [ngClass]="{'route-active': isActive('dashboard')}">
            <mat-icon mat-list-icon>dashboard</mat-icon>
            <div class="nav-title" mat-line>Dashboard</div>
        </li>
    </ng-container>

    <li (click)="toggleMenu('/faq')" [ngClass]="{'route-active': isActive('faq')}">
        <mat-icon mat-list-icon>forum</mat-icon>
        <div class="nav-title" mat-line>FAQs</div>
    </li>

     <li (click)="toggleMenu('/about')" [ngClass]="{'route-active': isActive('about')}">
        <mat-icon mat-list-icon>supervisor_account</mat-icon>
        <div class="nav-title" mat-line>About Us</div>
    </li>
</ul>

