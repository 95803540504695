import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FAQComponent {
  general;
  points;
  uploads;
  subscription;
  misc;

  constructor() {
    this.general = [
      {
        topic: 'How does this thing work anyways?',
        answer: 'We take scanned documents (PDFs) you upload, and use OCR to output readable, searchable formats with reliable and consistent accuracy.',
      },
      {
        topic: 'Follow-up question: what the heck is OCR?',
        answer: 'OCR = Optical Character Recognition. A process used to extract text from images (like scanned documents),'
        + ' and convert printed characters into digital data.',
      }
    ];

    this.uploads = [
      {
        topic: 'Is there a file size/page limit for uploads?',
        answer: 'Yes. Current limit is 4GB per file, with a page limit of 3000.',
      },
      {
        topic: 'Can I upload more than one document at a time?',
        answer: 'Patience, friend! We will have this feature in a future iteration, if our'
        + ' users want it - so let us know what features you want (hint, hint).',
      },
      {
        topic: 'I accidentally deleted a document. Is it possible to retrieve it?',
        answer: 'Game over man. Unfortunately, deleting a document is a permanent action and cannot be undone.'
        + ' This is because we don\'t keep copies of any client files - delete means delete.'
      },
      {
        topic: 'I uploaded document(s) to download, but they are no longer in my dashboard. What gives?',
        answer: 'Housekeeping! Documents are automatically deleted after 30 days.',
      },
    ];

    this.points = [
      {
        topic: 'Why do some uploads cost more "points" than others?',
        answer: 'We determine upload cost based upon the size (page count) of the document, and how many types of output formats you requested.'
        + ' Each page of output costs 1 point, so a 10 page document with both JSON and PDF output formats would cost 20 points.'
      },
      {
        topic: 'I had available points when uploading a document. When I checked my dashboard, the upload failed. Whats up with that?',
        answer: 'Most likely cause for this is insufficient points. Because we cannot calculate the cost of each upload until we begin processing,'
        + ' this means you will receive an error message indicating more points are required to complete the upload.'
        + ' A *quiq* way to avoid this problem is to determine the number of document pages before you upload and see'
        + ' if you have enough points (1 point per 1 page for each output format desired).',
      },
      {
        topic: 'Can I purchase more points?',
        answer: 'Not yet. We want to work out any existing kinks or problems before users begin paying for QuiqText services. So please let us know'
        + ' if you run into any issues or would like us to add any features. We plan to roll out payment subscription tiers in the near future.',
      },
    ];

    this.subscription = [
      {
        topic: 'What is included with my free subscription?',
        answer: 'Upon initial sign up, you receive 30 complimentary "points" to process documents. Your free points are automatically refreshed at the start of each month.',
      },
      {
        topic: 'How long is my subscription period good for?',
        answer: '365 days. After this time, your subscription will enter a temporary period of suspension, in which you will be provided with options to renew'
        + ' and review your current plan terms.',
      },
      {
        topic: 'My account is in renewal, but I have points available. Why can\'t I upload?',
        answer: 'During this short period, you may download previously uploaded documents, but uploading new documents is disabled.'
      },
    ];
  }
}
