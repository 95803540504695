import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserInfo } from 'src/app/models/user-info.model';
import { LocalStorageHelper } from 'src/app/helpers/local-storage.helper';
import slsConfig from 'src/serverless-stack-output.json';

@Injectable({
  providedIn: 'root',
})
export class UserInfoService {
  public endPoint;
  public info = new BehaviorSubject<UserInfo>(null);
  private storageHelper = new LocalStorageHelper();
  public isShowPointsForm = false;
  public points = new BehaviorSubject<UserInfo>(null);

  constructor(
    private http: HttpClient,
  ) {
    this.endPoint = slsConfig.ServiceEndpoint;
  }

  async getPoints(): Promise<void> {
    try {
      const currentSID = this.storageHelper.getItem('CurrentPlanID');
      const apiURL = `${this.endPoint}/subscriptions/${currentSID}`;
      const res = await this.http.get<any>(apiURL).toPromise();
      if (res.data && res.data.subscription) {
        this.points.next({
          pointsLeft: res.data.subscription.points_left,
          pointsUsed: res.data.subscription.points_used,
        });
      }
      return res;
    } catch (error) {
      return error;
    }
  }

  getUserInfo(): Observable<any> {
    return this.info.asObservable();
  }
}
