import { Pipe, PipeTransform } from '@angular/core';
import filter from 'lodash.filter';

@Pipe({
    name: 'uploadStatus',
    pure: true
})

export class UploadStatusPipe implements PipeTransform {
        transform(items: any[], state: any[]): any {
                if (!items || !state) {
                        return items || [];
                }

                return filter(items, item => {
                        return state.includes(item.state);
                });
        }
}
