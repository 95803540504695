import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

export const ROUTE_CACHING_KEY = 'current_route';

@Injectable({ providedIn: 'root' })
export class RouterService {
  subscription = new BehaviorSubject<string>('');

  constructor(public router?: Router) {
    const route = window.location.pathname.replace('/', '');
    this.setCurrentRoute(route);
  }

  getCurrentRoute(): string {
    let currentRoute = JSON.parse(
      JSON.stringify(localStorage.getItem(ROUTE_CACHING_KEY))
    );
    if (!currentRoute) {
      currentRoute = 'home';
    } else {
      currentRoute = currentRoute;
    }
    return currentRoute.replace('/', '');
  }

  setCurrentRoute(route: string): void {
    return localStorage.setItem(ROUTE_CACHING_KEY, route);
  }

  navigate(url: string): void {
    this.subscription.next(url.replace('/', ''));
    this.setCurrentRoute(url.replace('/', ''));
  }

  go(url: string): any {
    const currentRoute = url ? url.replace('/', '') : this.getCurrentRoute();
    return this.router?.navigateByUrl(currentRoute);
  }
}
