import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// MATERIAL UI
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatStepperModule } from '@angular/material/stepper';
import { MatBadgeModule } from '@angular/material/badge';

import Auth from '@aws-amplify/auth';
import { AmplifyAngularModule, AmplifyModules, AmplifyService } from 'aws-amplify-angular';
import { NgxFilesizeModule } from 'ngx-filesize';
import { FileUploadModule } from 'ng2-file-upload';
import { RouterTestingModule } from '@angular/router/testing';
import { UploadHistoriesComponent } from './pages/dashboard/upload-histories/upload-histories.component';
import { UploadItemComponent } from './pages/dashboard/upload-item/upload-item.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { FriendlyFilesizePipe } from './pipes/friendly-filesize.pipe';
import { DatetimePipe } from './pipes/datetime.pipe';
import { CreditFormComponent } from './components/credit-form/credit-form.component';
import { ConfirmDeleteComponent } from './directives/confirm-dialog/confirm-delete.directive';
import { FeedbackFormComponent } from './components/feedback-form/feedback-form.component';
import { BarRatingModule } from 'ngx-bar-rating';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxMdModule } from 'ngx-md';
import { PopupComponent } from './components/popup/popup.component';
import { NoticeComponent } from './directives/notice/notice.directive';
import { UploadStatusPipe } from './pipes/upload-status.pipe';
// import { DownloadPromptComponent } from './directives/download-prompt/download-prompt.component';
import { CancelDialogDirective } from './directives/cancel-dialog/dialog.directive';
import { MessageDialogComponent } from './directives/message-dialog/message-dialog';
import { SortPipe } from './pipes/sort.pipe';
import { ConvertFiletypePipe } from './pipes/convert-filetype.pipe';
// import { PaymentFormComponent } from './components/payment-form/payment-form.components';
import { MessagesComponent } from './components/messages/messages.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HttpConfigInterceptor } from './interceptor/http.interceptor';
import { UnlessPointsDialogComponent } from './directives/unless-points-dialog/unless-points-dialog.component';

@NgModule({
        declarations: [
                UploadHistoriesComponent,
                UploadItemComponent,
                DashboardComponent,
                FriendlyFilesizePipe,
                DatetimePipe,
                CreditFormComponent,
                ConfirmDeleteComponent,
                FeedbackFormComponent,
                CancelDialogDirective,
                MessageDialogComponent,
                UnlessPointsDialogComponent,
                // DownloadPromptComponent,
                SortPipe,
                UploadStatusPipe,
                ConvertFiletypePipe,
                NoticeComponent,
                PopupComponent,
                MessagesComponent,
                // PaymentFormComponent,
        ],
        imports: [
                CommonModule,
                AmplifyAngularModule,
                MatListModule,
                MatDividerModule,
                MatToolbarModule,
                MatCardModule,
                MatButtonModule,
                MatInputModule,
                MatIconModule,
                MatDialogModule,
                MatProgressBarModule,
                MatProgressSpinnerModule,
                HttpClientModule,
                MatSidenavModule,
                LayoutModule,
                NgxFilesizeModule,
                AmplifyAngularModule,
                MatFormFieldModule,
                MatPaginatorModule,
                ReactiveFormsModule,
                FormsModule,
                MatTabsModule,
                MatTooltipModule,
                MatCheckboxModule,
                MatExpansionModule,
                MatMenuModule,
                MatStepperModule,
                MatRadioModule,
                BarRatingModule,
                NgxMdModule,
                // DeviceDetectorModule.forRoot(),
                FontAwesomeModule,
                RouterTestingModule,
                MatBadgeModule
        ],
        providers: [
                {
                        provide: AmplifyService,
                        useFactory: () => {
                                return AmplifyModules({
                                        Auth
                                });
                        }
                },
                {
                        provide: HTTP_INTERCEPTORS,
                        useClass: HttpConfigInterceptor,
                        multi: true
                },
                DeviceDetectorService,
        ],
        entryComponents: [
                MessageDialogComponent,
                ConfirmDeleteComponent,
                FeedbackFormComponent,
                UnlessPointsDialogComponent,
        ],
        exports: [
                CommonModule,
                MatListModule,
                MatDividerModule,
                MatToolbarModule,
                MatCardModule,
                MatIconModule,
                MatInputModule,
                MatDialogModule,
                MatButtonModule,
                MatProgressBarModule,
                MatProgressSpinnerModule,
                MatSidenavModule,
                MatMenuModule,
                MatPaginatorModule,
                FormsModule,
                MatTabsModule,
                MatFormFieldModule,
                ReactiveFormsModule,
                MatTooltipModule,
                MatCheckboxModule,
                MatExpansionModule,
                RouterTestingModule,
                FileUploadModule,
                NgxFilesizeModule,
                MatRadioModule,
                NgxMdModule,
                FontAwesomeModule,
                // UploadHistoriesComponent,
                UploadItemComponent,
                ConvertFiletypePipe,
                // DashboardComponent,
                // FriendlyFilesizePipe,
                // DatetimePipe,
                CreditFormComponent,
                MessageDialogComponent,
                MatBadgeModule,
                // RouteCachingGuard,
        ]
})

export class SharedModule {}
