import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-terms-service',
	templateUrl: './terms-service.component.html',
	styleUrls: ['./terms-service.component.scss']
})
export class TermsServiceComponent implements OnInit {
	public scrollspyData = {
		sectionId: 'introduction',
		parentId: null
	};

	// -- Data for scrollspy navigation menu
  	// -- is_parent: assigned to a parent navigation item
  	// -- is_child: assigned to child of a parent navigation item
	// -- parent: the id of the parent navigation item
	public scrollContent = [
		{id: 'introduction', title: 'Terms of Service'},
		{id: 'limitations-of-use', title: 'Limitations of Use', is_parent: true},
		{id: 'intellectual-property', title: 'Intellectual Property'},
		{id: 'liability', title: 'Liability'},
		{id: 'accuracy-of-materials', title: 'Accuracy of Materials'},
		{id: 'links', title: 'Links'},
		{id: 'right-to-terminate', title: 'Right to Terminate'},
		{id: 'severance', title: 'Severance'},
		{id: 'governing-law', title: 'Governing Law'}
	];

	constructor() { }

	ngOnInit(): void {
		// --On page load, forcing target redirect to top of page
		document.querySelector('body').scrollIntoView({ block: 'start', behavior: 'smooth' });
	}

	onSectionChange(data): void {
		this.scrollspyData = data;
	}

	// --On click behavior for scrollspy links
	scrollTo(section): void {
		document.querySelector('#' + section).scrollIntoView({ block: 'start', behavior: 'smooth' });

		this.scrollspyData.sectionId = section;
	}
}
