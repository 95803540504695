import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Subject } from 'rxjs';
import { Menu } from 'src/app/models/menu.model';
import { LocalStorageHelper } from 'src/app/helpers/local-storage.helper';
import { RouterService } from './router.service';

@Injectable({
    providedIn: 'root'
})
export class MenuService {
    public menuSub = new Subject<Menu>();
    public menuAction: Menu;
    public theme = new BehaviorSubject<string>('');
    public storageHelper = new LocalStorageHelper();
    public currentNavItem = new BehaviorSubject<string>('');

    constructor(public routerService: RouterService) {
        this.theme.next(this.getCurrentTheme());

        // -- On load, getting current route to set navigation item
        this.currentNavItem.next(this.routerService.getCurrentRoute());
    }

    dispatch(action: Menu): void {
        switch (action.type) {
            case 'MENU_IS_OPENING': {
                this.menuAction = {
                    type: action.type,
                    payload: {
                        menuStyle: 'menu'
                    }
                };
                return this.menuSub.next(this.menuAction);
            }
            case 'MENU_IS_CLOSED': {
                this.menuAction = {
                    type: action.type,
                    payload: {
                        menuStyle: 'menu-hidden'
                    }
                };
                return this.menuSub.next(this.menuAction);
            }
            default:
                break;
        }
    }

    getCurrentTheme(): string {
        const currentTheme = this.storageHelper.getItem('current_theme');
        if (!currentTheme) {
            return 'light';
        }
        return currentTheme;
    }

    changeTheme(): void {
        if (this.theme.getValue() === 'dark') {
            this.theme.next('light');
            this.storageHelper.setItem('current_theme', 'light');
        } else {
            this.theme.next('dark');
            this.storageHelper.setItem('current_theme', 'dark');
        }
    }

    // -- Make new route selection known for nav menus
    changeRoute(path: string): void {
        path = path.replace('/', '');
        this.routerService.setCurrentRoute(path);

        // --Setting new active navigation item
        this.currentNavItem.next(path);
    }
}
