import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Enum } from 'src/app/constants/enum.constant';
import { MessageConst } from 'src/app/constants/message.constant';
import { MessageService } from 'src/app/services/message.service';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { RouterService } from 'src/app/services/router.service';
import { UploaderService } from 'src/app/services/uploader.service';
import { AuthService } from 'src/app/services/auth.service';
import { UserInfoService } from 'src/app/services/user-info.service';
import { Observable } from 'rxjs';
import { UserInfo } from 'src/app/models/user-info.model';
import { MenuService } from 'src/app/services/menu.service';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
})

export class MenuComponent implements OnInit {
@Output() toggleMenuEvent = new EventEmitter();
    windowSize: number;
    uploadStatus = false;
    public currentNavItem = 'home';
    public currentPlan: Observable<string>;
    public points: Observable<UserInfo>;
    public isLoggedIn = null;

    constructor(
        public uploaderService: UploaderService,
        public router: Router,
        public dialog: MatDialog,
        private rs: ResponsiveService,
        private messageService: MessageService,
        public routerService: RouterService,
        public auth: AuthService,
        public userInfo: UserInfoService,
        private authService: AuthService,
        public menuService: MenuService
    ) {
        // -- Watching navigation item selection
        menuService.currentNavItem.asObservable().subscribe(route => {
            this.currentNavItem = route;
        });

        this.currentPlan = this.authService.currentPlan.asObservable();
        this.points = this.userInfo.points.asObservable();
        this.routerService.subscription.asObservable()
        .subscribe(route => {
            if (route) { this.currentNavItem = route; }
        });
        this.isLoggedIn = this.auth.getLoggedInUser();
    }

    toggleMenu(path: string): void {
        // --Close out navigation menu if mobile menu is active, upon changing route
        if (this.windowSize < 767 && !this.uploadStatus){
            this.toggleMenuEvent.emit();
        }
        if (!this.uploaderService.isUploading) {
            this.menuService.changeRoute(path);
            this.uploaderService.uploadStatus.next(false);
            this.router.navigateByUrl(this.currentNavItem);
            window.history.pushState('', '', '');
            return;
        }
        this.messageService.set({
            message: MessageConst.navigateConfirmation,
            type: Enum.Warning,
            toPath: path,
            txtBtnRight: 'Cancel'
        });
    }

    // --Determining the active navigation menu item and assigning appropriate CSS class
    isActive(navItem: string): boolean {
        return this.currentNavItem === navItem;
    }

    ngOnInit(): void {
        this.userInfo.getPoints();
        // --Detecting window size on first load
        this.windowSize = window.innerWidth;
        this.uploaderService.uploadStatus.next(false);
        // --Subscribing to window resize event to detect new window sizes
        this.rs.onResize$.subscribe((event) => {
            this.windowSize = event.width;
        });
    }
}
