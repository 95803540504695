import { Injectable } from '@angular/core';
import { Error } from 'src/app/models/error.model';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError, from } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ErrorHandleService } from 'src/app/services/error-handle.service';
import { AuthService } from '../services/auth.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    constructor(private errorHandleService: ErrorHandleService, private authService: AuthService) {}
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (!request.headers.has('Content-Type') && !request.responseType) {
            request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        }

        if (request.responseType === 'blob') {
            request = request.clone({ headers: request.headers.set('Accept', '*/*') });
        }

        return from(this.getToken(request, next)).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    // to do something if have condition
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                const err: Error = {
                    reason: error && error.error && error.error.reason ? error.error.reason : '',
                    status: error?.status,
                    error: error.error,
                };
                this.errorHandleService.setError(err);
                return throwError(error);
        }));
    }

    async getToken(req: HttpRequest<any>, next: HttpHandler): Promise<any> {
        if (!req.headers.has('None-Auth')) {
            const token = await this.authService.onGetToken();
            const authReq = req.clone({
                setHeaders: {
                  Authorization: 'Bearer ' + token,
                }
            });
            return next.handle(authReq).toPromise();
        }
        return next.handle(req).toPromise();
    }
}
