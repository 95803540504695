import { Component, ViewEncapsulation, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { UploaderService } from 'src/app/services/uploader.service';
import { UserInfoService } from 'src/app/services/user-info.service';
import { MatDialog } from '@angular/material/dialog';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { faFilePdf, faFileAlt } from '@fortawesome/free-regular-svg-icons';
import { Document } from 'src/app/models/document.model';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-upload-histories',
    templateUrl: './upload-histories.component.html',
    styleUrls: ['./upload-histories.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class UploadHistoriesComponent implements OnDestroy {
    @ViewChild('uploadsTable', {static: false}) uploadsTable;
    items: any;
    isLoading = true;
    selected = 0;
    isSplitted = false;
    reportingData: boolean;
    jsonDownloads: Document[] = [];
    pdfDownloads: Document[] = [];
    faArrowDown = faArrowDown;
    faFilePdf = faFilePdf;
    faFileAlt = faFileAlt;
    downloadFiles: Document[];

    constructor(
        public uploaderService: UploaderService,
        public creditService: UserInfoService,
        public downloadPrompt: MatDialog,
        public dialog: MatDialog,
        public cdr: ChangeDetectorRef,
        public http: HttpClient,
        ) {
            // uploaderService.getFiles(); // Get Jobs from API
            uploaderService.getJobsPage().subscribe(items => this.items = items);

            uploaderService.getCheckedDocuments().subscribe(items => {
                this.jsonDownloads = items.filter(item => item.output_formats.includes('json'));
                this.pdfDownloads = items.filter(item => item.output_formats.includes('pdf'));
            });

            setTimeout(() => {
                this.isLoading = false;
                if (this.items.length) { this.uploadsTable?.realignInkBar(); }
            }, 2000);
    }

    getTabIndex(index): void {
        this.uploaderService.updateFilterState(index);
    }

    downloads(type): void {
        if (type === 'json') {
            const jsonForDownloads = JSON.parse(JSON.stringify(this.jsonDownloads));
            for (let i = 0, p = Promise.resolve(); i < jsonForDownloads.length; i++) {
                p = p.then(async () => {
                    try {
                        await this.uploaderService.downloadFile(jsonForDownloads[i], 'json');
                    } catch (error) {
                        console.error(error?.status);
                    }
                });
            }
        }
        if (type === 'pdf') {
            const pdfForDownloads = JSON.parse(JSON.stringify(this.pdfDownloads));
            for (let i = 0, p = Promise.resolve(); i < pdfForDownloads.length; i++) {
                p = p.then(async () => {
                    try {
                        await this.uploaderService.downloadFile(pdfForDownloads[i], 'pdf');
                    } catch (error) {
                        console.error(error?.status);
                    }
                });
            }
        }
    }

    asyncDownloads = async (index, type) => {
        if (type === 'json') {
            try {
                await this.uploaderService.downloadFile(this.jsonDownloads[index], 'json');
            } catch (error) {
                console.error(error?.status);
            }
            if (this.jsonDownloads && this.jsonDownloads[index]) {
                this.jsonDownloads[index].downloaded_dt = new Date().toString();
            } else {
                console.log();
            }
            // --Hiding download button after completion
            this.jsonDownloads = [];

            this.cdr.detectChanges();
        }
        if (type === 'pdf') {
            try {
                await this.uploaderService.downloadFile(this.pdfDownloads[index], 'pdf');
            } catch (error) {
                console.error(error?.status);
            }
            if (this.pdfDownloads && this.pdfDownloads[index]) {
                this.pdfDownloads[index].downloaded_dt = new Date().toString();
            } else {
                console.log();
            }

            // --Hiding download button after completion
            this.pdfDownloads = [];

            this.cdr.detectChanges();
        }
    }

    ngOnDestroy(): void {
        // --After leaving view, reset JSON and PDF download observables
        this.uploaderService.resetJobsToInit();
    }
}
