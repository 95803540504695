


<div id="not-found_wrapper">
    <div class="container">
        <h1>4<img src="../assets/logo_quiq-text_lg.png" alt="quiqtext logo" class="logo-img" />4</h1>
        <h2>Page not found.</h2>

        <a href="/" class="back-home-link">Quiq, let's go home.</a>
    </div>
</div>
