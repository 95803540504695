import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { RouterService } from '../services/router.service';

@Injectable({
    providedIn: 'root'
})
export class SubscriptionGuard implements CanActivate {
    constructor(
        private readonly authService: AuthService,
        private readonly routerService: RouterService,
        ) {}
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.isHasPlan();
    }

    public async isHasPlan(): Promise<boolean> {
        const plan = await this.authService.currentPlan.getValue();
        if (plan) { return true; }
        this.routerService.go('subscriptions');
        return false;
    }
}
