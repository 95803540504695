import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-unless-point-dialog',
    styleUrls: ['unless-points-dialog.component.scss'],
    templateUrl: 'unless-points-dialog.component.html'
})

export class UnlessPointsDialogComponent {
    constructor(public dialogRef: MatDialogRef<any>,
                @Inject(MAT_DIALOG_DATA) public data: any) {}

    onConfirm(): void {
        this.dialogRef.close();
    }
}
