// MODULES
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { AuthService } from './services/auth.service';
import { ErrorHandleService } from './services/error-handle.service';
import { RouterService } from './services/router.service';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { WaitingComponent } from './components/waiting/waiting.component';
import { MenuComponent } from './components/menu/menu.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { ChangelogComponent } from './components/changelog/changelog.component';
import { AppContainerComponent } from './pages/container.component';
import { UploaderService } from './services/uploader.service';
import { SharedModule } from './app.shared.modules';
import { LoginComponent } from './pages/auth/pages/login/login.component';
import { CommonModule } from '@angular/common';
import { SubscriptionsComponent } from './pages/subscriptions/subscriptions.component';
import { DownloadPromptComponent } from './directives/download-prompt/download-prompt.component';
import { UserInfoService } from './services/user-info.service';
import { UploaderComponent } from './components/uploader/uploader.component';
import { HomeComponent } from './pages/home/home.component';
import { FAQComponent } from './pages/faq/faq.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { TermsServiceComponent } from './pages/terms-service/terms-service.component';
import { CookiePolicyComponent } from './pages/cookie-policy/cookie-policy.component';
import { UsePolicyComponent } from './pages/use-policy/use-policy.component';
import { ScrollSpyDirective } from './directives/scrollspy/scrollspy.directive';
import { AuthGuard } from './guards/auth.guard';

@NgModule({
    declarations: [
        AppComponent,
        MenuComponent,
        NavbarComponent,
        LoginComponent,
        WaitingComponent,
        NotFoundComponent,
        AppContainerComponent,
        SubscriptionsComponent,
        ChangelogComponent,
        UploaderComponent,
        HomeComponent,
        FAQComponent,
        PrivacyPolicyComponent,
        TermsServiceComponent,
        CookiePolicyComponent,
        UsePolicyComponent,
        ScrollSpyDirective,
    ],
    imports: [
        BrowserModule,
        CommonModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        RouterModule,
        FormsModule,
        MatDialogModule,
        SharedModule,
    ],
    providers: [
        AuthService,
        RouterService,
        UploaderService,
        ErrorHandleService,
        UserInfoService
    ],
    entryComponents: [ChangelogComponent, DownloadPromptComponent],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor() {
        if (window.location.origin.includes('wwww')) {
            window.location.replace(
                window.location.origin.replace('www.', '') + window.location.pathname + window.location.search
            );
        }
    }
}
