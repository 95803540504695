<mat-toolbar class="toolbar mat-accent">
    <mat-toolbar-row>
        <button id="nav_menu-btn" mat-icon-button (click)="toggleMenu()"><mat-icon>menu</mat-icon></button>
        <!--<span id="welcome-user"></span>-->

        <span class="spacer"></span>

        <button *ngIf="isLoggedIn | async" #toggleButton (click)="togglePointsPopup()" class="rounded-icon-btn {{theme}}" id="toggle-points-menu_btn">
            <ng-container *ngIf="(points | async) as p">
                <div matBadge="{{p?.pointsLeft}}"
                    matBadgePosition="after"
                    matBadgeColor="accent"
                    [ngClass]="{'large-badge': p?.pointsLeft > 99}">

                    <div #menu2 [class.show]="isPointsPopupOpen" id="points-info_modal">
                        <div class="arrow-up"></div>
                        <app-credit-form
                            [delayTime]="0"
                            [addMore]="true"
                            [pointsLeft]="p?.pointsLeft"
                            [pointsUsed]="p?.pointsUsed">
                        </app-credit-form>
                    </div>
                </div>
            </ng-container>
        </button>

        <!--Login button-->
        <button *ngIf="!loggedInUser" mat-flat-button class="rounded-icon-btn logout {{theme}}" routerLink='login' (click)="login('login')">
            <span class="icon light">
                <img src="/assets/icon_login-light.png" alt="light mode icon" width="18" height="22" />
            </span>
            <span class="icon dark">
                <img src="/assets/icon_login-dark.png" alt="dark mode icon" width="18" height="22" />
            </span>
        </button>

        <button mat-flat-button class="rounded-icon-btn {{theme}}" (click)="changeTheme()">
            <span class="icon light">
                <img src="/assets/icon_moon-light.png" alt="light mode icon" width="21" height="21" />
            </span>
            <span class="icon dark">
                <img src="/assets/icon_moon-dark.png" alt="dark mode icon" width="21" height="21" />
            </span>
        </button>

        <button *ngIf="isLoggedIn | async" mat-flat-button class="rounded-icon-btn logout {{theme}}" (click)="signout()">
            <span class="icon light">
                <img src="/assets/icon_logout-light.png" alt="light mode icon" width="18" height="22" />
            </span>
            <span class="icon dark">
                <img src="/assets/icon_logout-dark.png" alt="dark mode icon" width="18" height="22" />
            </span>
        </button>
    </mat-toolbar-row>
</mat-toolbar>
