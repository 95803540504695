import { Directive, Input, EventEmitter, Output, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appScrollSpy]'
})

export class ScrollSpyDirective {
	@Input() public spiedTags = [];
	@Output() public sectionChange = new EventEmitter <{sectionId: string, parentId: string}> ();

    private currentSection: string;
	private currentParent: string;

    constructor(private contentElement: ElementRef) {}

    @HostListener('scroll', ['$event'])
    onScroll(event: any): void {
        let currentSection: string;
        let currentParent: string;

        const children = this.contentElement.nativeElement.children;
        const scrollTop = event.target.scrollTop;
        const parentOffset = event.target.offsetTop + 100;

        // for (let i = 0; i < children.length; i++) {

        for (const element of children) {
            if (this.spiedTags.some(spiedTag => spiedTag === element.tagName)) {
                if ((element.offsetTop - parentOffset) <= scrollTop) {
                    currentSection = element.id;

                    if (element.className.indexOf('parent_') !== -1) {
						const classEl = element.className.split('parent_')[1];
						currentParent = classEl;
					}
					else {
						currentParent = '';
					}
                }
            }
        }

        if (currentSection !== this.currentSection || currentParent !== currentParent) {
            this.currentSection = currentSection;
            this.currentParent = currentParent;
            this.sectionChange.emit({sectionId: this.currentSection, parentId: this.currentParent});
        }

    }

}
