<ng-container>
    <mat-sidenav-container [class]="theme | async" id="app">
        <mat-sidenav
                [mode]="isMenuMobile ? 'push' : 'side'"
                opened
                [(opened)]="opened"
                [fixedInViewport] = "true">
            <app-menu (toggleMenuEvent)="opened = !opened"></app-menu>
        </mat-sidenav>

        <mat-sidenav-content>
            <header>
                <app-navbar (toggleMenuEvent)="opened = !opened" [class]="theme | async" ></app-navbar>
            </header>
            <main>
                <div class="container">
                    <router-outlet></router-outlet>
                </div>
                <button id="feedback-form_btn" mat-fab aria-label="Example icon-button with a heart icon" (click)="openFeedbackForm()">
                    <mat-icon>assignment</mat-icon>
                </button>
            </main>
            <footer>
               <div class="container">
                    <div id="version-row">Version {{version}}: &nbsp; [<a (click)="openChangelog()">CHANGELOG</a>]</div>
                    <div id="policies-row">
                        <a routerLink='privacy-policy' (click)="setRoute('privacy-policy')" [ngClass]="{'active': currentNavItem === 'privacy-policy'}">Privacy Policy</a>&#124;
                        <a routerLink='cookie-policy' (click)="setRoute('cookie-policy')" [ngClass]="{'active': currentNavItem === 'cookie-policy'}">Cookie Policy</a>&#124;
                        <a routerLink='terms-service' (click)="setRoute('terms-service')" [ngClass]="{'active': currentNavItem === 'terms-service'}">Terms of Service</a>&#124;
                        <a routerLink='use-policy' (click)="setRoute('use-policy')" [ngClass]="{'active': currentNavItem === 'use-policy'}">Use Policy</a>
                    </div>
                </div>
            </footer>

        </mat-sidenav-content>
    </mat-sidenav-container>
</ng-container>