import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
	public scrollspyData = {
		sectionId: 'introduction',
		parentId: null
	};

	// -- Data for scrollspy navigation menu
  	// -- is_parent: assigned to a parent navigation item
  	// -- is_child: assigned to child of a parent navigation item
	// -- parent: the id of the parent navigation item
	public scrollContent = [
		{id: 'introduction', title: 'Privacy Policy'},
		{id: 'information-we-collect', title: 'Information We Collect', is_parent: true},
		{id: 'log-data', title: 'Log Data', is_child: true, parent: 'information-we-collect'},
		{id: 'device-data', title: 'Device Data', is_child: true, parent: 'information-we-collect'},
		{id: 'personal-information', title: 'Personal Information', is_child: true, parent: 'information-we-collect'},
		{id: 'personal-information-2', title: 'Processing Your Personal Information', is_child: true, parent: 'information-we-collect'},
        {id: 'information-use', title: 'Collection & Use of Information', is_child: true, parent: 'information-we-collect'},
        {id: 'personal-information-3', title: 'Personal Information Security', is_child: true, parent: 'information-we-collect'},
        {id: 'personal-information-4', title: 'Saving Personal Information', is_child: true, parent: 'information-we-collect'},
		{id: 'children-privacy', title: 'Childrens Privacy'},
		{id: 'personal-information-5', title: 'Third Parties'},
		{id: 'personal-information-6', title: 'International Transfers of Personal Information'},
		{id: 'your-rights', title: 'Your Rights'},
		{id: 'cookies', title: 'Use of Cookies'},
		{id: 'business-transfers', title: 'Business Transfers'},
		{id: 'policy-limits', title: 'Limits of Our Policy'},
		{id: 'policy-changes', title: 'Changes to This Policy'},
		{id: 'disclosures-1', title: 'Additional Disclosures (AU)'},
		{id: 'disclosures-2', title: 'Additional Disclosures (EU)', is_parent: true},
		{id: 'disclosures-2-1', title: 'Data Controller / Data Processor', is_child: true, parent: 'disclosures-2'},
		{id: 'disclosures-2-2', title: 'Protecting Your Personal Information', is_child: true, parent: 'disclosures-2'},
		{id: 'disclosures-2-3', title: 'International Transfers Outside EEA', is_child: true, parent: 'disclosures-2'},
		{id: 'disclosures-2-4', title: 'Your Rights and Personal Information', is_child: true, parent: 'disclosures-2'},
		{id: 'disclosures-3', title: 'Additional Disclosures for California'},
		{id: 'disclosures-3-1', title: 'Do Not Track', is_child: true, parent: 'disclosures-3'},
		{id: 'disclosures-3-2', title: 'Cookies and Pixels', is_child: true, parent: 'disclosures-3'},
		{id: 'disclosures-3-3', title: 'CCPA-permitted financial incentives', is_child: true, parent: 'disclosures-3'},
		{id: 'disclosures-3-4', title: 'California Notice of Collection', is_child: true, parent: 'disclosures-3'},
		{id: 'disclosures-3-5', title: 'Right to Know and Delete', is_child: true, parent: 'disclosures-3'},
		{id: 'disclosures-3-6', title: 'Shine the Light', is_child: true, parent: 'disclosures-3'},
		{id: 'contact-us', title: 'Contact Us'}
	];

	constructor() { }

	ngOnInit(): void {
		// --On page load, forcing target redirect to top of page
		document.querySelector('body').scrollIntoView({ block: 'start', behavior: 'smooth' });
	}

	onSectionChange(data): void {
		this.scrollspyData = data;
	}

	// --On click behavior for scrollspy links
	scrollTo(section): void {
		document.querySelector('#' + section).scrollIntoView({ block: 'start', behavior: 'smooth' });

		this.scrollspyData.sectionId = section;
	}

}
