import { Pipe, PipeTransform } from '@angular/core';
import isEqual from 'lodash.isequal';

@Pipe({
    name: 'sort',
})
export class SortPipe implements PipeTransform {
    transform(items: any[], ...args: any[]): any {
        if (items) {
            const state = JSON.parse(JSON.stringify(items));
            if (args[0].key.includes('created')) {
                const sort = items.sort((a, b) => {
                    const aCreated = new Date(a.sk.replace('job#', '')).getTime();
                    const bCreated = new Date(b.sk.replace('job#', '')).getTime();
                    if (!args[0].isDown) {
                        return bCreated - aCreated;
                    }
                    else {
                        return aCreated - bCreated;
                    }
                });
                if (isEqual(state, sort)) {
                    return sort.reverse();
                }
                return sort;
            }
            if (args[0].key.includes('file') || args[0].key.includes('size')) {
                const sort = items.sort((a, b) => {
                    if (!args[0].isDown) {
                        if (a[args[0].key] < b[args[0].key]) {
                            return -1;
                        }
                        return 1;
                    } else {
                        if (a[args[0].key] < b[args[0].key]) {
                            return 1;
                        }
                        return -1;
                    }
                });
                if (isEqual(state, sort)) {
                    return sort.reverse();
                }
                return sort;
            }
        } else {
            return [];
        }
    }
}
