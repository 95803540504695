import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { AuthService } from './services/auth.service';
import { RouterService } from './services/router.service';

@Component({
  selector: 'app-root',
  template: `
    <app-waiting *ngIf="status === 'Checking'"></app-waiting>
    <router-outlet *ngIf="status !== 'Checking'"></router-outlet>
  `,
})
export class AppComponent {
  status: string;
  constructor(
    public routerService: RouterService,
    public authService: AuthService,
    private router: Router,
    private title: Title,
    private meta: Meta
  ) {
    this.seoEvents();
    routerService.subscription
      .asObservable()
      .subscribe((route) => routerService.go(route));
  }

  seoEvents(): void {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        switch (event.urlAfterRedirects) {
          case '/home':
            this.title.setTitle('QuiqText');
            this.meta.updateTag({
              name: 'description',
              content:
                'Upload PDF documents with QuiqText, extracting text using OCR',
            });
            break;
          case '/dashboard':
            this.title.setTitle('Dashboard');
            this.meta.updateTag({
              name: 'description',
              content:
                'Manage your uploads with QuiqText dashboard and download documents',
            });
            break;
          case '/faq':
            this.title.setTitle('FAQ');
            this.meta.updateTag({
              name: 'description',
              content:
                'QuiqText FAQs, questions answered about our service and text extraction',
            });
            break;
          case '/about':
            this.title.setTitle('About Us');
            this.meta.updateTag({
              name: 'description',
              content:
                'QuiqText FAQs, questions answered about our service and text extraction',
            });
            break;
          case '/privacy-policy':
            this.title.setTitle('Privacy Policy');
            this.meta.updateTag({
              name: 'description',
              content:
                'QuiqText FAQs, questions answered about our service and text extraction',
            });
            break;
          case '/terms-service':
            this.title.setTitle('Terms Service');
            this.meta.updateTag({
              name: 'description',
              content:
                'QuiqText FAQs, questions answered about our service and text extraction',
            });
            break;
          case '/use-policy':
            this.title.setTitle('Use Policy');
            this.meta.updateTag({
              name: 'description',
              content:
                'QuiqText FAQs, questions answered about our service and text extraction',
            });
            break;
          case '/cookie-policy':
            this.title.setTitle('Cookie Policy');
            this.meta.updateTag({
              name: 'description',
              content:
                'QuiqText FAQs, questions answered about our service and text extraction',
            });
            break;
          case '/login':
            this.title.setTitle('QuiqText');
            this.meta.updateTag({
              name: 'description',
              content:
                'Upload PDF documents with QuiqText, extracting text using OCR',
            });
            break;
        }
      }
    });
  }
}
