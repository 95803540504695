<div id="dialog-notice">
    <mat-dialog-content>
        <div class="header warning-header">
            <img src="../../../assets/icon_warning.png" width="24" height="24" alt="warning icon" srcset="">
            <span>Warning!</span>
        </div>
        <div class="warning-confirmation"><b>{{ data.message }}</b></div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button class="confirm mat-flat-button mgr-5" (click)="onConfirm('YES')">YES</button>
        <button class="cancel mat-flat-button" (click)="onConfirm('NO')">NO</button>
    </mat-dialog-actions>
</div>