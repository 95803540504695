import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'datetime'
})
export class DatetimePipe implements PipeTransform {

    transform(value: string, ...args: any[]): any {
        if (value) {
            return value.replace('job#', '');
        }
        return '';
    }

}
