import { Component, Inject, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-download-prompt',
  templateUrl: './download-prompt.component.html',
  styleUrls: ['./download-prompt.component.scss'],
})
export class DownloadPromptComponent {
  downloadStarted = new EventEmitter<string>();
  status;
  isMultiDownloads;
  constructor(
    public dialogRef?: MatDialogRef<DownloadPromptComponent>,
    @Inject(MAT_DIALOG_DATA) public data?: any
  ) {
    if (data && data.isMultiDownloads) {
      this.isMultiDownloads = data.isMultiDownloads;
    } else {
      console.log();
    }
    this.downloadStarted.subscribe((e) => {
      this.status = e;

      // --Once the download has completed (success or fail), hide dialog
      if (
        e.includes('download-successful') ||
        e.includes('download-unsuccessful')
      ) {
        // setTimeout(() => {
          this.dialogRef.close();
        // }, 2000);
      } else {
        return null;
      }
    });
  }
}
