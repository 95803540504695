<div id="login-wrapper">
    <div id="login-container">
        <div id="login-form">
            <img src="/assets/logo_light.png" width="120" alt="quiqtext logo" class="logo-img responsive-img" />

            <h2>Quiq<span>Text</span></h2>

            <div class="header">
                <h4>Serverless Optical Character Recognition And Text Extraction Service</h4>
            </div>

            <h4 class="social-login_header">To begin, sign in with your social account:</h4>

            <div class="social-login_wrapper">
                <div class="col">
                    <button mat-flat-button (click)="onLogin('Google')" id="login-google">
                        <img src="../../../../../assets/logo_google.png" alt="" srcset="">
                    </button>
                </div>

                <div class="col">
                    <button mat-flat-button (click)="onLogin('Amazon')" id="login-amazon">
                        <img src="../../../../../assets/logo_amazon.png" alt="" srcset="">
                    </button>
                </div>

                <div class="col">
                    <button mat-flat-button (click)="onLogin('Facebook')" id="login-fb">
                        <img src="../../../../../assets/logo_facebook.png" alt="" srcset="">
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>