<div *ngIf="!formSubmitted">
	<h2>We value and appreciate your feedback!</h2>
	<h3>Please let us know about your experience so we can better serve you.</h3>

	<mat-horizontal-stepper #stepper [disableRipple]="true" [linear]="true">
		<mat-step [stepControl]="loginFeedback" >

			<form [formGroup]="loginFeedback" [ngClass]="{'submitted': loginSubmitted === true}">

				<div class="form-row">
					<div class="feedback-form-field">
						<ng-template matStepLabel>Login Experience</ng-template>

						<mat-label>Did you experience any issues logging in?</mat-label>
						<mat-radio-group formControlName="loginProblems" required>
							<mat-radio-button value="yes">Yes</mat-radio-button>
							<mat-radio-button value="no">No</mat-radio-button>
						</mat-radio-group>

						<div class="form-field_more-detail" *ngIf="loginFeedback.get('loginProblems').value === 'yes'">
							<mat-label>Please describe any problems you had logging in.</mat-label>
							<textarea matInput formControlName="loginProblemsDetail"></textarea>
						</div>
					</div>

					<div class="feedback-form-field">
						<mat-label>How would you rate your login experience?</mat-label>
						<bar-rating formControlName="loginRating" [max]="5" [theme]="'square'" required></bar-rating>
					</div>
				</div>
				<div>
					<button mat-button matStepperNext (click)="loginSubmitted = true">Next</button>
				</div>
			</form>
		</mat-step>

		<mat-step [stepControl]="uploadFeedback">
			<form [formGroup]="uploadFeedback" [ngClass]="{'submitted': uploadSubmitted === true}">
				<div class="form-row">
					<div class="feedback-form-field">
						<ng-template matStepLabel>Uploading files</ng-template>

						<mat-label>Were you able to successfully OCR one or more documents?</mat-label>
						<mat-radio-group formControlName="uploadSuccess" required>
							<mat-radio-button value="yes">Yes</mat-radio-button>
							<mat-radio-button value="no">No</mat-radio-button>
						</mat-radio-group>
					</div>

					<div class="feedback-form-field">
						<mat-label>How would you rate your upload experience?</mat-label>
						<bar-rating formControlName="uploadExperienceRating" [max]="5" [theme]="'square'" required></bar-rating>
					</div>
				</div>

				<div class="form-row">
					<div class="feedback-form-field">
						<ng-template matStepLabel>Uploading files</ng-template>

						<mat-label>Did you experience any difficulties uploading documents?</mat-label>
						<mat-radio-group formControlName="uploadProblems" required>
							<mat-radio-button value="yes">Yes</mat-radio-button>
							<mat-radio-button value="no">No</mat-radio-button>
						</mat-radio-group>

						<div class="form-field_more-detail" *ngIf="uploadFeedback.get('uploadProblems').value === 'yes'">
							<mat-label>Please explain in more detail the problems you while uploading.</mat-label>
							<textarea matInput formControlName="uploadProblemsDetail"></textarea>
						</div>
					</div>

					<div class="feedback-form-field">
						<mat-label>How would you rate the upload interface?</mat-label>
						<bar-rating formControlName="uploadUIRating" [max]="5" [theme]="'square'" required></bar-rating>
					</div>
				</div>

				<div class="feedback-form_btn-wrapper">
					<button mat-button matStepperPrevious>Back</button>
					<button mat-button matStepperNext (click)="uploadSubmitted = true">Next</button>
				</div>
			</form>
		</mat-step>


		<mat-step [stepControl]="dashboardFeedback">
			<form [formGroup]="dashboardFeedback" [ngClass]="{'submitted': dashboardSubmitted === true}">
				<div class="form-row">
					<div class="feedback-form-field">
						<ng-template matStepLabel>Managing your files</ng-template>

						<mat-label>Were you able to easily track files you had uploaded?</mat-label>
						<mat-radio-group formControlName="dashTracking" required>
							<mat-radio-button value="yes">Yes</mat-radio-button>
							<mat-radio-button value="no">No</mat-radio-button>
						</mat-radio-group>
					</div>

					<div class="feedback-form-field">
						<mat-label>How would you rate the dashboard interface?</mat-label>
						<bar-rating formControlName="dashUIRating" [max]="5" [theme]="'square'" required></bar-rating>
					</div>
				</div>

				<div class="form-row">
					<div class="feedback-form-field">

						<mat-label>Did you encounter any problems navigating your uploads?</mat-label>
						<mat-radio-group formControlName="dashUXProblems" required>
							<mat-radio-button value="yes">Yes</mat-radio-button>
							<mat-radio-button value="no">No</mat-radio-button>
						</mat-radio-group>

						<div class="form-field_more-detail" *ngIf="dashboardFeedback.get('dashUXProblems').value === 'yes'">
							<mat-label>Please describe the issues you experienced.</mat-label>
							<textarea matInput formControlName="dashUXProblemsDetail"></textarea>
						</div>
					</div>
				</div>

				<div class="feedback-form_btn-wrapper">
					<button mat-button matStepperPrevious>Back</button>
					<button mat-button matStepperNext (click)="dashboardSubmitted = true">Next</button>
				</div>
			</form>
		</mat-step>

		<mat-step [stepControl]="downloadFeedback">
			<form [formGroup]="downloadFeedback" [ngClass]="{'submitted': downloadSubmitted === true}">
				<div class="form-row">
					<div class="feedback-form-field">
						<ng-template matStepLabel>Downloading &amp; Deleting Documents</ng-template>

						<mat-label>Were you able to succesfully download a file you had previously uploaded?</mat-label>
						<mat-radio-group formControlName="downloadSuccess" required>
							<mat-radio-button value="yes">Yes</mat-radio-button>
							<mat-radio-button value="no">No</mat-radio-button>
						</mat-radio-group>

						<div class="form-field_more-detail" *ngIf="downloadFeedback.get('downloadSuccess').value === 'no'">
							<mat-label>Please explain in more detail the difficulties you experienced.</mat-label>
							<textarea matInput formControlName="downloadProblemsDetail"></textarea>
						</div>
					</div>

					<div class="feedback-form-field">
						<mat-label>If you OCR'd a document as a PDF file, how would you rate the quality of the PDF file(s)?</mat-label>
						<bar-rating formControlName="downloadQuality" [max]="5" [theme]="'square'" required></bar-rating>
					</div>
				</div>

				<div class="form-row">
					<div class="feedback-form-field">
						<mat-label>Were you able to successfully delete a file?</mat-label>
						<mat-radio-group formControlName="deleteSuccess" required>
							<mat-radio-button value="yes">Yes</mat-radio-button>
							<mat-radio-button value="no">No</mat-radio-button>
						</mat-radio-group>

						<div class="form-field_more-detail" *ngIf="downloadFeedback.get('deleteSuccess').value === 'no'">
							<mat-label>Please explain in more detail the difficulties you experienced.</mat-label>
							<textarea matInput formControlName="deleteProblemsDetail"></textarea>
						</div>
					</div>

					<div class="feedback-form-field">
						<mat-label>Overall, did you find it easy to manage your files?</mat-label>
						<bar-rating formControlName="manageFilesRating" [max]="5" [theme]="'square'" required></bar-rating>
					</div>
				</div>

				<div class="feedback-form_btn-wrapper">
					<button mat-button matStepperPrevious>Back</button>
					<button mat-button (click)="submitFeedback()" type="button" id="submitBtn">Submit Feedback</button>
				</div>
			</form>
		</mat-step>
	</mat-horizontal-stepper>
</div>

<div *ngIf="formSubmitted">
	<div id="feedback-form_success">
		<h2 class="submit-header" *ngIf="isProcessing">Submitting your responses...</h2>
		<div id="wave" *ngIf="isProcessing">
			<span class="dot"></span>
			<span class="dot"></span>
			<span class="dot"></span>
		</div>

		<div *ngIf="!isProcessing" id="done-processing">
			<div *ngIf="submitSuccess === true">
				<h2 class="submit-header">Success!</h2>
				<h3>Thank you for your feedback.</h3>

				<button [mat-dialog-close]>Exit</button>
			</div>

			<div *ngIf="submitSuccess === false">
				<h2 class="submit-header">There was a problem submitting your feedback.</h2>
				<h3>We apologize for the inconvenience. Please try again later.</h3>

				<button [mat-dialog-close]>Exit</button>
			</div>
		</div>
	</div>
</div>