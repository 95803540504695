import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
    selector: 'app-notice',
    styleUrls: ['notice.directive.scss'],
    templateUrl: 'notice.directive.html'
})

export class NoticeComponent {
    constructor(public dialogRef: MatDialogRef<any>,
                @Inject(MAT_DIALOG_DATA) public data: any, public router: Router ) {}

    onConfirm(isConfirmed: string): void {
        if (isConfirmed === 'YES') {
            this.router.navigateByUrl(this.data.navigateTo);
            return;
        }
        this.dialogRef.close();
    }
}
