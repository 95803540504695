import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import slsConfig from 'src/serverless-stack-output.json';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: ['./feedback-form.component.scss'],
	encapsulation: ViewEncapsulation.None
})

export class FeedbackFormComponent implements OnInit {
	loginFeedback: FormGroup;
	uploadFeedback: FormGroup;
	dashboardFeedback: FormGroup;
	downloadFeedback: FormGroup;
	deviceInfo;
	loginSubmitted;
	uploadSubmitted;
	dashboardSubmitted;
	downloadSubmitted;
	formSubmitted;
	isProcessing;
	submitSuccess;

	constructor(
		private http: HttpClient,
		private deviceService: DeviceDetectorService,
		public auth: AuthService,
		private fb: FormBuilder,
		public dialog: MatDialog) {}

	ngOnInit(): void {
		this.buildForm();
		this.setValidators();
	}

	buildForm(): void {
		this.loginFeedback = this.fb.group({
			loginProblems: ['', Validators.required],
			loginProblemsDetail: [''],
			loginRating: ['', Validators.required]
		});

		this.uploadFeedback = this.fb.group({
			uploadSuccess: ['', Validators.required],
			uploadExperienceRating: ['', [Validators.required]],
			uploadUIRating: ['', [Validators.required]],
			uploadProblems: [''],
			uploadProblemsDetail: ['']
		});

		this.dashboardFeedback = this.fb.group({
			dashTracking: ['', Validators.required],
			dashUIRating: ['', [Validators.required]],
			dashUXProblems: [''],
			dashUXProblemsDetail: ['']
		});

		this.downloadFeedback = this.fb.group({
			downloadSuccess: ['', Validators.required],
			downloadProblemsDetail: [''],
			downloadQuality: ['', Validators.required],
			deleteSuccess: ['', Validators.required],
			deleteProblemsDetail: [''],
			manageFilesRating:  ['', Validators.required]
		});

		this.deviceInfo = {
			general: this.deviceService.getDeviceInfo(),
			isDesktop: this.deviceService.isDesktop(),
			isMobile: this.deviceService.isMobile(),
			isTablet: this.deviceService.isTablet()
		};

	}

	setValidators(): void {
		this.loginFeedback.get('loginProblems').valueChanges.subscribe(loginProblems => {
			if (loginProblems === 'yes') {
				this.loginFeedback.get('loginProblemsDetail').setValidators([Validators.required]);
			}

			else {
				this.loginFeedback.get('loginProblemsDetail').setValidators(null);
			}

			this.loginFeedback.get('loginProblemsDetail').updateValueAndValidity();

		});

		this.uploadFeedback.get('uploadProblems').valueChanges.subscribe(uploadProblems => {
			if (uploadProblems === 'yes') {
				this.uploadFeedback.get('uploadProblemsDetail').setValidators([Validators.required]);
			}

			else {
				this.uploadFeedback.get('uploadProblemsDetail').setValidators(null);
			}

			this.uploadFeedback.get('uploadProblemsDetail').updateValueAndValidity();

		});

		this.dashboardFeedback.get('dashUXProblems').valueChanges.subscribe(dashUXProblems => {
			if (dashUXProblems === 'yes') {
				this.dashboardFeedback.get('dashUXProblemsDetail').setValidators([Validators.required]);
			}

			else {
				this.dashboardFeedback.get('dashUXProblemsDetail').setValidators(null);
			}

			this.dashboardFeedback.get('dashUXProblemsDetail').updateValueAndValidity();

		});

		this.downloadFeedback.get('downloadSuccess').valueChanges.subscribe(downloadSuccess => {
			if (downloadSuccess === 'no') {
				this.downloadFeedback.get('downloadProblemsDetail').setValidators([Validators.required]);
			}

			else {
				this.downloadFeedback.get('downloadProblemsDetail').setValidators(null);
			}

			this.downloadFeedback.get('downloadProblemsDetail').updateValueAndValidity();

		});

		this.downloadFeedback.get('deleteSuccess').valueChanges.subscribe(deleteSuccess => {
			if (deleteSuccess === 'no') {
				this.downloadFeedback.get('deleteProblemsDetail').setValidators([Validators.required]);
			}

			else {
				this.downloadFeedback.get('deleteProblemsDetail').setValidators(null);
			}

			this.downloadFeedback.get('deleteProblemsDetail').updateValueAndValidity();

		});
	}

	submitFeedback(): Promise<void | any> {
		this.downloadSubmitted = true;

		if (this.downloadFeedback.valid === true){
			this.formSubmitted = true;
			this.isProcessing = true;

			return new Promise(async (resolve, reject) => {
				const sid = this.auth.currentPlanID.getValue();
				const endPoint = slsConfig.ServiceEndpoint;
				const userEmail = this.auth.user.email;

				const apiURL = `${endPoint}/subscriptions/${sid}/users/${userEmail}/feedback`;

				const finalData = {
					login: this.loginFeedback.value,
					upload: this.uploadFeedback.value,
					dashboard: this.dashboardFeedback.value,
					download: this.downloadFeedback.value,
					device: this.deviceInfo
				};

				this.http.post(apiURL, finalData)
					.toPromise()
					.then(res => {
						this.isProcessing = false;
						this.submitSuccess = true;
						resolve(res);
					})
					.catch(err => {
						this.isProcessing = false;
						this.submitSuccess = false;
						console.log('There was a problem submitting the feedback form.');
					});
			});
		} else {
			return null;
		}
	}
}
