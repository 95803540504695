import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-use-policy',
  templateUrl: './use-policy.component.html',
  styleUrls: ['./use-policy.component.scss']
})
export class UsePolicyComponent implements OnInit {
	public scrollspyData = {
		sectionId: 'introduction',
		parentId: null
	};

	// -- Data for scrollspy navigation menu
  	// -- is_parent: assigned to a parent navigation item
  	// -- is_child: assigned to child of a parent navigation item
	// -- parent: the id of the parent navigation item
	public scrollContent = [
		{id: 'introduction', title: 'Use Policy'},
		{id: 'fair-use', title: 'Fair Use'},
		{id: 'customer-accountability', title: 'Customer Accountability'},
		{id: 'prohibited-activity', title: 'Prohibited Activity', is_parent: true},
		{id: 'prohibited-activity-1', title: 'SPAM and unauthorized message activity', is_child: true, parent: 'prohibited-activity'},
		{id: 'prohibited-activity-2', title: 'Unethical, exploitative, and malicious activity', is_child: true, parent: 'prohibited-activity'},
		{id: 'prohibited-activity-3', title: 'Unauthorized use of QuiqText property', is_child: true, parent: 'prohibited-activity'},
		{id: 'about-policy', title: 'About this Policy'}
	];

	constructor() { }

	ngOnInit(): void {
		// --On page load, forcing target redirect to top of page
		document.querySelector('body').scrollIntoView({ block: 'start', behavior: 'smooth' });
	}

	onSectionChange(data): void {
		this.scrollspyData = data;
	}

	// --On click behavior for scrollspy links
	scrollTo(section): void {
		document.querySelector('#' + section).scrollIntoView({ block: 'start', behavior: 'smooth' });

		this.scrollspyData.sectionId = section;
	}
}
