import {
    Component,
    EventEmitter,
    Output,
    ViewEncapsulation,
    Renderer2,
    Inject,
    ElementRef,
    ViewChild
} from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { MenuService } from 'src/app/services/menu.service';
import { DOCUMENT } from '@angular/common';
import { Observable } from 'rxjs';
import { UserInfo } from 'src/app/models/user-info.model';
import { UserInfoService } from 'src/app/services/user-info.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NavbarComponent {
    @Output() toggleMenuEvent = new EventEmitter();
    public theme;
    public points: Observable<UserInfo>;
    @ViewChild('toggleButton', {static: false}) toggleButton: ElementRef;
    @ViewChild('menu2', {static: false}) menu2: ElementRef;
    isPointsPopupOpen = false;
    public isLoggedIn = null;
    public loggedInUser = null;

    constructor(
        public auth: AuthService,
        public menu: MenuService,
        private renderer: Renderer2,
        public userInfo: UserInfoService,
        @Inject(DOCUMENT) private document: Document) {
        menu.theme.asObservable().subscribe(theme => {
            if (theme === 'light') {
                this.theme = 'dark';
                // --Applying a css class to the body, so that styles can be applied
                this.renderer.addClass(this.document.body, 'light');
                this.renderer.removeClass(this.document.body, 'dark');
            }
            else {
                this.theme = 'light';
                // --Applying a css class to the body, so that styles can be applied
                this.renderer.addClass(this.document.body, 'dark');
                this.renderer.removeClass(this.document.body, 'light');
            }

        });

        this.points = this.userInfo.points.asObservable();

        this.isLoggedIn = this.auth.getLoggedInUser();
        this.loggedInUser = this.isLoggedIn.value;

        // --Adding a listener to detect when the user clicks outside of the points menu, and closes it
        if (this.loggedInUser) {
            this.renderer.listen('window', 'click', (e: Event) => {
                if (e.target !== this.toggleButton.nativeElement && e.target !== this.menu2.nativeElement){
                    this.isPointsPopupOpen = false;
                }
            });
        }

    }

    // -- Selecting new active navigation item
    login(path: string): void {
        this.menu.changeRoute(path);
        window.history.pushState('', '', '');
    }

    togglePointsPopup(): void {
        this.isPointsPopupOpen = !this.isPointsPopupOpen;
    }

    toggleMenu(): void {
        this.toggleMenuEvent.emit();
    }

    changeTheme(): void {
        this.menu.changeTheme();
    }

    signout(): void {
        this.auth.onLogout();
    }
}
