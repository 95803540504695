export const slsConfigReader = (configs) => {
        const awsmetadata = {
                aws_project_region: configs.Region,
                aws_cognito_identity_pool_id: configs.IdentityPoolId,
                aws_cognito_region: configs.Region,
                aws_user_pools_id: configs.UserPoolId,
                aws_user_pools_web_client_id: configs.UserPoolClientWebId,
                oauth: {
                        domain: configs.UserPoolDomain.replace('https://', ''),
                        scope: [
                                'phone',
                                'email',
                                'openid',
                                'profile',
                                'aws.cognito.signin.user.admin'
                        ],
                        redirectSignIn: configs.CallbackURL,
                        redirectSignOut: configs.CallbackURL,
                        responseType: 'code'
                },
                federationTarget: 'COGNITO_USER_POOLS',
                aws_content_delivery_bucket: configs.WebAppS3Bucket,
                aws_content_delivery_bucket_region: configs.Region,
                aws_content_delivery_url: configs.CallbackURL,
        };
        return awsmetadata;
};

