import { Component, OnInit, NgZone } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { AuthService } from 'src/app/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { FeedbackFormComponent } from 'src/app/components/feedback-form/feedback-form.component';
import { Observable } from 'rxjs';
import { LocalStorageHelper } from 'src/app/helpers/local-storage.helper';
import { ChangelogComponent } from 'src/app/components/changelog/changelog.component';
import { version } from '../../../package.json';
import { MenuService } from '../services/menu.service';
import { RouterService } from '../services/router.service';

@Component({
    selector: 'app-container',
    templateUrl: 'container.html',
})

export class AppContainerComponent implements OnInit {
    signedInStatus;
    user: any = null;
    authorized: Observable<any>;
    theme: Observable<string>;
    public version: string = version;
    public currentPlan: Observable<string>;
    public currentNavItem;

    constructor(
        public authService: AuthService,
        public breakpointObserver: BreakpointObserver,
        public dialog: MatDialog,
        private zone: NgZone,
        private menu: MenuService,
        public routerService: RouterService,
        public menuService: MenuService
    ) {
        // -- Watching navigation item selection
        menuService.currentNavItem.asObservable().subscribe(route => {
            this.currentNavItem = route;
        });

        this.signedInStatus = new LocalStorageHelper().getItem('AuthorizeStatus');
        this.theme = this.menu.theme.asObservable();
        this.currentPlan = this.authService.currentPlan.asObservable();
        this.authService.cognito.asObservable()
        .subscribe(auth => {
            if (auth) {
                this.zone.run(() => {
                    this.signedInStatus = auth.status;
                });
            } else {
                console.log();
            }
        });
    }

    // ----SIDE MENU DEFAULT SETTINGS----//
    opened = true;
    isMenuMobile = false;

    ngOnInit(): void {
        // Get initialed state

        // --Side navigation media query detection
        this.breakpointObserver
            .observe(['(max-width: 768px)'])
            .subscribe((state: BreakpointState) => {

            // --Determine when to switch side menu mode from 'side' to 'push'
            this.isMenuMobile = this.breakpointObserver.isMatched('(max-width: 768px)');

            // --Viewport smaller than 768px
            if (state.matches) {
                // --Menu should be closed.
                this.opened = false;
            } else {
                // --Menu should be open.
                this.opened = true;
            }
        });
    }

    // -- Selecting new active navigation item
    setRoute(path: string): void {
        this.menu.changeRoute(path);
        window.history.pushState('', '', '');
    }

	openFeedbackForm(): void {
		this.dialog.open(FeedbackFormComponent, {
            width: '900px',
            maxHeight: '95vh',
			disableClose: false,
			panelClass: 'feedback-form_wrapper',
			data: {}
		});
    }

    openChangelog(): void {
		this.dialog.open(ChangelogComponent, {
            width: '700px',
            maxHeight: '95vh',
			disableClose: false,
			panelClass: 'changelog_wrapper',
			data: {}
		});
    }
}
