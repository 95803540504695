import { Component, Input, OnChanges, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserInfoService } from 'src/app/services/user-info.service';

@Component({
    selector: 'app-credit-form',
    templateUrl: './credit-form.component.html',
    styleUrls: ['./credit-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CreditFormComponent implements OnInit, OnDestroy {
    @Input() pointsInfo = 0;
    @Input() pointsUsed = 0;
    @Input() pointsLeft = 0;
    @Input() delayTime = 0;
    @Input() addMore = false;
    public endPoint;
    public pointsValue;
    public showPointsForm = false;
    public pointsData;
    public isLoading = true;

    constructor(
        private userInfo: UserInfoService,
        public messagePrompt: MatDialog,
    ) {
        this.endPoint = 'slsConfig.ServiceEndpoint';
    }

    // updatePoints(value: string): void {
    //     this.userInfo.updatePoints(value)
    //     .then(isClosed => {
    //         this.showPointsForm = isClosed;
    //         this.userInfo.isShowPointsForm = isClosed;
    //     })
    //     .catch(error => {
    //         console.log(error)
    //     })
    // }

    ngOnInit(): void {
        setTimeout(() => {
            this.showPointsForm = this.userInfo.isShowPointsForm;
        }, this.delayTime);
    }

    // waitingTransition(seconds): Promise<void> {
    //     return new Promise(resolve => {
    //         setTimeout(() => resolve(), seconds * 1000);
    //     });
    // }

    // async ngOnChanges(e): Promise<void> {
    //     if (e.pointsLeft.currentValue || e.pointsLeft.currentValue === 0) {
    //         await this.waitingTransition(1);
    //         this.isLoading = false;
    //     }
    // }

    ngOnDestroy(): void {
        this.userInfo.isShowPointsForm = false;
    }
}
