import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertFiletype'
})
export class ConvertFiletypePipe implements PipeTransform {

  transform(fileName: string, ...args: string[]): any {
    if (fileName && args.length) {
      const fileType = args[0].toLocaleLowerCase();
      return fileName.split('.')[0] + '.' + fileType;
    } else {
      return '';
    }
  }
}
